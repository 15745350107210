import {getAlarm2, getUserAlarm} from "@/api/impl/alarm";
import {parseOneLine} from "@/utils/device/DetectorValueParser";
import device from "@/store/modules/device";
import {axiosServer} from "@/api/judge";

const table = {
    state: {
        // 用户实时告警
        warnData:[],
        warnLoad: true,
        warnTotal: 0,
        // 监管单位
        monitorComData: {
            charts: {},
            tableData: []
        },
        monitorLoad: true,

        deviceAlarmInfo: {
          list: [],
          total: 0
        },
        alarmLoad: true,
    },
    mutations: {
        // 设置实时告警记录
        SET_WARNING_DATA(state,payload){
            if (payload.type === 1) state.warnData = payload.res;
            else if (payload.type === 2) state.warnData = state.warnData.concat(payload.res);
        },
        // 设置实时告警表格加载状态
        SET_WARNING_LOAD(state, load){
            state.warnLoad = load;
        },
        SET_WARNING_Total(state, num){
            state.warnTotal = num;
        },
        // 设置告警单位统计
        SET_MONITOR_COM_DATA(state, res){
            state.monitorComData.tableData = res.comStatistic;
            state.monitorComData.charts = res.stateStatistic;
        },
        // 设置告警单位统计
        SET_MONITOR_COM_STATE(state, stateStatistic){
            console.log("===state===")
            state.monitorComData.charts = stateStatistic;
        },
        // 设置告警单位统计
        SET_MONITOR_COM_ALARM(state, comStatistic){
            console.log("===state===")
            state.monitorComData.tableData = comStatistic;
        },
        // 设置告警单位统计表格加载状态
        SET_MONITOR_LOAD(state, payload){
            state.monitorLoad = payload;
        },
        // 设置单个设备告警记录
        SET_DEVICE_ALARM_INFO(state, res){
            state.deviceAlarmInfo.list = res.records;
            state.deviceAlarmInfo.total = res.total;
        },
        // 设置单个设备告警记录表格加载状态
        SET_ALARM_LOAD(state, payload){
            state.alarmLoad = payload;
        }
    },
    actions:{
        // 获取实时告警数据
        GET_WARNING_DATA(context, payload){
            console.log("====payload", payload)
            context.commit('SET_WARNING_LOAD', true)


            axiosServer({
                url: "/display/alarm/list?page=" + payload.body.page + "&pageSize=" + payload.body.pageSize,
                type: "GET",
            }).then(res => {
                if (res.code === 200){
                    console.log("====alarm===")
                    console.log(res)
                    context.commit('SET_WARNING_LOAD', false)
                    context.commit('SET_WARNING_DATA', { res: res.result.records, type: payload.type})
                    context.commit('SET_WARNING_Total', res.result.total)
                }
            })
            // getUserAlarm(payload.body)
            //     .then((res) => {
            //         console.log('getUserAlarm:', res)
            //         if (res.code == 0){
            //             //
            //             res.page.list.forEach((item) => {
            //                 let parsedStatus = parseOneLine(item.gjdj);
            //                 item.statusValue = parsedStatus[1];
            //             });
            //             context.commit('SET_WARNING_DATA', { res: res.page.list, type: payload.type})
            //             context.commit('SET_WARNING_LOAD', false)
            //             context.commit('SET_WARNING_Total', res.page.totalCount)
            //         }
            //
            //         // this.userAlarmLoading = false;
            //     })
            //     .catch((e) => {
            //         context.commit('SET_WARNING_LOAD', false)
            //         // this.userAlarmLoading = false;
            //         // console.log(e)
            //     });
        },
        // 获取告警单位
        GET_MONITOR_DATA(context, userId){
            context.commit('SET_MONITOR_LOAD', true)
            axiosServer({
                url: "/display/statistics/com",
                type: "GET"
            }).then(res => {
                if (res.code === 200){
                    console.log(res)
                    context.commit("SET_MONITOR_COM_STATE", res.result)
                }

            })
            axiosServer({
                url: "/display/statistics/com/alarm",
                type: "GET"
            }).then(res => {
                if (res.code === 200){
                    context.commit('SET_MONITOR_LOAD', false)
                    context.commit("SET_MONITOR_COM_ALARM", res.result)
                }
            }).catch(e => {
                context.commit('SET_MONITOR_LOAD', false)
            })
            // axiosServer({
            //     url: '/statistic/display/alarm?userId=' + userId ,
            //     type: 'GET',
            // }).then(res => {
            //     console.log('monitorStatisticalData', res)
            //     if (res.data.code == 0){
            //         context.commit('SET_MONITOR_COM_DATA', res.data);
            //         context.commit('SET_MONITOR_LOAD', false)
            //     }
            //
            // }).catch(e => {
            //     context.commit('SET_MONITOR_LOAD', false)
            // })
        },
        // 获取单个设备的告警记录
        GET_ALARM_DATA(context, respBody){
            context.commit('SET_ALARM_LOAD', true)
            axiosServer({
                url: "/display/alarm/list/device?page=" + respBody.page + "&pageSize=" + respBody.pageSize + "&deviceId=" + respBody.deviceId,
                type: "GET"
            }).then(res => {
                context.commit('SET_DEVICE_ALARM_INFO', res.result)
            })
            // getAlarm2(respBody).then(res => {
            //     if (res.code == 0){
            //         res.page.list.map(item => {
            //             item.alarmId = item.id
            //             item.regionName = res.device.regionName;
            //             item.deviceName = res.device.deviceName;
            //             item.ip = res.device.ip
            //             // item.valueFormat = item.jcz;
            //             let oneLineValue = parseOneLine(item.gjdj);
            //             item.statusValue = oneLineValue[1];
            //             if (item.jcz == 0) {
            //                 if (oneLineValue[0] == 1) {
            //                     //越上限
            //                     item.valueFormat = "大于" + item.maxValue;
            //                 } else if (oneLineValue[0] == 3) {
            //                     //越下限
            //                     item.valueFormat = "小于" + item.minValue;
            //                 }
            //             }
            //             res.sensors.map(it => {
            //                 if (it.looperNum == item.tdh){
            //                     item.unit = it.unit;
            //                     // item.jcz = it.
            //                 }
            //             })
            //         })
            //         console.log('getAlarmById2:',res)
            //         context.commit('SET_DEVICE_ALARM_INFO', res.page)
            //         context.commit('SET_ALARM_LOAD', false)
            //     }
            //
            // }).catch(e => {
            //     context.commit('SET_ALARM_LOAD', false)
            // })
        }
    }
}
export default table
