<template>
    <el-container id="display-body" class="main-content">
      <el-header class="header">
        <fire-header></fire-header>
      </el-header>
      <el-container class="content">
        <!--- 地图区 -->
        <el-aside>
          <map-content></map-content>
          <right-stat></right-stat>
        </el-aside>
        <!--右侧 结束-->
      </el-container>
    </el-container>
</template>

<script>
import FireHeader from "@/views/Layout/Header/Header";
import MapContent from "@/views/Layout/Main/MainLeft/MapContent";
import RightStat from "@/views/Layout/Main/MainRight/RightStat";
import baseUrl from "@/api/baseUrl";
import {axiosServer} from "@/api/judge";
import bus from "@/utils/eventBus/bus";
export default {
  name: "Home",
  components:{
    FireHeader,
    MapContent,
    RightStat
  },
  created() {
    console.log('======Created======')
    // 页面刚进入时开启长连接
    this.initWebSocket();
    // 开启全局定时器

  },
  data(){
    return{
      count: 0,
      timer: null,
      webSocketInstance: null,
      wsTimer: null,
    }
  },

  mounted(){

  },
  methods:{
    initWebSocket: function () {
      // WebSocket与普通的请求所用协议有所不同，ws等同于http，wss等同于https
      let url = baseUrl.WS_URL + '/display' + localStorage.getItem('userId');

      console.log("---websocket url---")
      console.log(url)
      let token = localStorage.getItem('token')
      this.websock = new WebSocket(url, [token]);
      this.websock.onopen = this.websocketOnopen;
      this.websock.onerror = this.websocketOnerror;
      this.websock.onmessage = this.websocketOnmessage;
      this.websock.onclose = this.websocketOnclose;
    },
    websocketOnopen: function () {
      console.log("WebSocket连接成功");
      //心跳检测重置
      //this.heartCheck.reset().start();
    },
    websocketOnerror: function (e) {
      console.log("WebSocket连接发生错误，第%s次",this.wsConnectErrorTime);

      this.wsConnectErrorTime = this.wsConnectErrorTime + 1;
      if(this.wsConnectErrorTime>5){
        console.log("WebSocket连接错误超过5次，就不再重新连了！");
        this.lockReconnect = true
        return;
      }

      this.reconnect();
    },
    websocketOnmessage: function (evt) {
      let data = JSON.parse(evt.data);
      let msg = data.msg;
      let code = data.code;
      if (code == 1001){
        bus.$emit('requestAlarm', {
          deviceId:  msg.devId,
          alarmId: msg.alarmId,
          appid: msg.appid,
          state: "1"
        });
      }
    },
    websocketOnclose: function (e) {
      console.log("connection closed (" + e + ")");
      if(e){
        console.log("connection closed (" + e.code + ")");
      }
      this.reconnect();
    },
    websocketSend(text) { // 数据发送
      try {
        this.websock.send(text);
      } catch (err) {
        console.log("send failed (" + err.code + ")");
      }
    },
    reconnect() {
      let that = this;
      if(that.lockReconnect) return;
      that.lockReconnect = true;
      //没连接上会一直重连，设置延迟避免请求过多
      this.timer = setTimeout(function () {
        console.info("尝试重连...");
        that.initWebSocket();
        that.lockReconnect = false;
      }, 20000);
    },
    // // sleep(ms){
    // //   return new Promise(res => {
    // //     setTimeout(res, ms);
    // //   })
    // // },
    // closeWSListenerTimer() {
    //   clearInterval(this.wsTimer);
    // },
    // connWebSocket() {
    //   // console.log('conWebSocket');
    //   let url = baseUrl.WS_URL + '/display' + localStorage.getItem('userId');
    //   console.log(url)
    //   this.webSocketInstance = new WebSocket(url, [localStorage.getItem('token')]);
    //   this.webSocketInstance.onopen = this.ws_open;
    //   this.webSocketInstance.onmessage = (evt) => {
    //     console.log(evt.data)
    //     let data = JSON.parse(evt.data);
    //     let msg = data.msg;
    //     let code = data.code;
    //     console.log(code)
    //     console.log(msg)
    //
    //     if (code == 1001){
    //       bus.$emit('requestAlarm', {
    //         deviceId:  msg.devId,
    //         alarmId: msg.alarmId,
    //         appid: msg.appid,
    //         state: "1"
    //       });
    //     }
    //     // // && data.alarmType == 1
    //     // if (code == 1001) {
    //     //   let msg = JSON.parse(data.msg);
    //     //   if (msg.alarmType == 1){
    //     //     let deviceId = JSON.parse(data.msg).deviceId;
    //     //     // this.requestAlarmCount();
    //     //     bus.$emit('requestAlarm', deviceId);
    //     //   }
    //     //   // eslint-disable-next-line no-empty
    //     // }
    //   };
    //   this.webSocketInstance.onclose = this.ws_close;
    // },
    // /**
    //  * 启动一个监听webSocket的监视器
    //  * 0 (CONNECTING)
    //  * 正在链接中
    //  * 1 (OPEN)
    //  * 已经链接并且可以通讯
    //  * 2 (CLOSING)
    //  * 连接正在关闭
    //  * 3 (CLOSED)
    //  * 连接已关闭或者没有链接成功
    //  * */
    // startWSListenerTimer() {
    //   let self = this
    //   // console.log('启动ws监听器');
    //   this.wsTimer = setInterval(() => {
    //     let wsState = this.webSocketInstance.readyState;
    //     //        console.log("ws state = " + wsState);
    //     if (wsState == 3) {
    //       // console.log('ws 断开了，重连');
    //       self.connWebSocket();
    //     }
    //   }, 1000);
    // },
    // // 测试websocket
    // getWarning(id){
    //   axiosServer({
    //     url: '/test/sendWSAlarmTest',
    //     type: 'get',
    //     params: {
    //       userId: localStorage.getItem('userId'),
    //       deviceId: id
    //     }
    //   }).then(res => {
    //     console.log('getWarning', res)
    //     if (res.data.code == 0){
    //       bus.$emit('requestAlarm', id);
    //     }
    //   })
    // }
  },
  destroyed() {
    clearTimeout(this.timer);
    this.timer = null;
    this.webSocketInstance.close();
  }
}
</script>

<style scoped>
.content-right{

  background-color: #101c42 !important;
  overflow-x: hidden !important;
}

.main-content{
  height: 100% !important;
  /*overflow-x: hidden;*/
}

.el-header, .el-footer {
  height: 70px !important;
}

.el-aside {
  text-align: center;
  width: 100% !important;
}

.el-main {
  background-color: #E9EEF3;
  color: #333;
  text-align: center;
  width: 25% !important;

}



body > .el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}

</style>
