<template>
<!--  <div class="baidumap" id="allmap" ref="refMap">-->
<!--  </div>-->
  <div class="baidumap" id="allmap">
<!--    :min-zoom="minZoom"-->
    <baidu-map :center="bmCenter"
               :scroll-wheel-zoom="true"
               :zoom="mapZoom"
               @ready="mapReady"

               ref="map"
               style="width: 100%; height: 100%;"
               z-index="1">
      <!-- 设备点的详细信息 -->

        <my-overlay :active="active"
                    :position="overlayDeviceInfo"
                    ref="overlay"></my-overlay>


    </baidu-map>
  </div>
</template>

<script>

import MyOverlay from "@/views/Layout/Main/MainLeft/components/map/MyOverlay";
import {isEmpty} from "@/utils/Objects";
import {getDeviceMap, getDeviceAlarmInfoLatest, getDeviceById} from "@/api/impl/device";
import bus from "@/utils/eventBus/bus";
import {getAlarmDetail} from "@/api/impl/alarm";
import {parseOneLine} from "@/utils/device/DetectorValueParser";


const LEVEL1_ZOOM = 19;
const LEVEL2_ZOOM = 8;
const LEVEL3_ZOOM = 11;
const LEVEL_B3_ZOOM = 12;

export default {
  name: 'BaiduMapShow',
  components: {MyOverlay},
  props: {
    msg: String
  },

  data(){
    return {
      mapGeo: undefined,
      map: undefined,
      active: false,
      bmCenter :{lng:113.79316, lat:23.044483},
      // bmCenter :{lng: null, lat:null},
      mapZoom: LEVEL1_ZOOM,
      // minZoom: LEVEL1_ZOOM,
      BMapCore: {},
      overlayDeviceInfo: {},
      deviceMapLocation: [],
      mapStyle:{

      },
      mapVDataset: null,
      mapVLayer: null,
      pointData: [],
      alarmShow: false,
      timer: null,
      interval: null,
      timeout: null,
      // 地图是否初始化过
      mapInit: false,
      // 是否渲染成功
      isInit: false
    }
  },
  computed:{
    sbzt(){
      return this.$store.getters.sbzt
    },
    deviceInfo(){
      return this.$store.getters.deviceInfo
    }
  },
  methods:{
     mapReady (BMap) {
      this.BMapCore = BMap;
      this.map = BMap.map;
      this.map.clickable = false;
      this.mapGeo = new this.BMapCore.BMap.Geocoder();
      this.map.setMapStyleV2({
        styleId: 'a119d5399eca13503db6c3fe7880f684'
      });
      // overlay默认为false
      this.LocationRequestDevice();
      this.$refs.overlay.showOverlay(false);
    },

     LocationRequestDevice(){
      getDeviceMap({ comId: null}).then(res => {
        console.log('getDeviceMap', res)
        this.deviceMapLocation = res.data
        // 如果地图没初始化 执行函数
        if (!this.mapInit){
          console.log('fillMapv执行')
          this.fillMapV();
        }
      }).catch(e => {
        this.pointData = [];
        console.log('getDeviceMap:error', e)
      })
    },

    fillMapV () {
      let mapv = require('mapv');
      let that = this
      let options = {
        zIndex: 3,
        draw: 'icon',
        methods: {
          click(item) {
            console.log('item', item)
            // item 可以知道点击位置是标注还是地图空白位置
            if (item){
              // if (that.alarmShow){
              //   // console.log(1111)
              // }else {
              //
              // }
              that.overlayDeviceInfo = item.deviceInfo;
              that.overlayDeviceInfo.status = 0;
              that.$refs.overlay.showOverlay(true, that.overlayDeviceInfo);
              that.$store.commit('SET_OVERLAY_CARD_TYPE', 0);
              that.$store.commit('SET_DEVICE_DETAILS_SHOW', true)
              that.$store.commit('SET_EXPAND', false)
            }else {
              that.$store.commit('SET_EXPAND', false)
              that.$refs.overlay.showOverlay(false);
              // if (that.alarmShow){
              //
              // }else {
              //   that.$refs.overlay.showOverlay(false);
              // }
            }
          }
        },

      };
      // this.sbzt根据 按钮显示不同的标注
      // this.pointData 是每个点的实例 this.deviceMapLocation只是点数据不包括图标等
      this.pointData = this.getMapDevicePoint(0);
      this.mapVDataset = new mapv.DataSet(this.pointData);
      this.mapVLayer = new mapv.baiduMapLayer(this.map, this.mapVDataset, options);
      // 执行了fillMapv函数代表已经初始化了
      this.mapInit = true;
      this.isInit = true;
    },

    getMapDevicePoint (sbzt) {
      let data = [];
      this.deviceMapLocation.forEach((item) => {
        if (!isEmpty(this.selectDevice)) {
          if (this.selectDevice.deviceId == item.deviceId) {
            this.selectDevice = item;
          }
        }
        if (item.sbzt == sbzt || sbzt == 0) {
          let img = new Image();
          switch (item.sbzt) {
            case 1:
              // img.src = "https://jc-app-store.oss-cn-shenzhen.aliyuncs.com/icon/green.png";
              img.src = require("../../../../../images/green.png");
              break;
            case 2:
              // img.src = "https://jc-app-store.oss-cn-shenzhen.aliyuncs.com/icon/grey.png";
              img.src = require("../../../../../images/grey.png");
              break;
            case 3:
              // img.src = "https://jc-app-store.oss-cn-shenzhen.aliyuncs.com/icon/yellow.png";
              img.src = require("../../../../../images/yellow.png");
              break;
            case 4:
              // img.src = "https://jc-app-store.oss-cn-shenzhen.aliyuncs.com/icon/red.png";
              img.src = require("../../../../../images/red.png");
              break;
            case 5:
              // img.src = "https://jc-app-store.oss-cn-shenzhen.aliyuncs.com/icon/blue.png";
              img.src = require("../../../../../images/blue.png");
              break;
            default:
              // img.src = "https://jc-app-store.oss-cn-shenzhen.aliyuncs.com/icon/grey.png";
              img.src = require("../../../../../images/grey.png");
          }
          data.push({
            geometry: {
              type: 'Point',
              coordinates: [item.lng, item.lat],
            },
            deviceInfo:item,
            // properties: {
            icon: img,
            size: 30,
            // }
          });
        }
      });
      return data;
    },

    async getDeviceAlarmInfo(id, isMove){
      let res = await getDeviceAlarmInfoLatest({deviceId: id});
      console.log('getDeviceAlarmInfoLatest' , res,'告警类型', res.alarm.alarmType === 1 ? 'alarm告警': '故障')
      if (res.code === 0 && res.hasAlarm){
        this.mapMove(res.device, true, 1, isMove)
            // 解析值
            let oneLineValue = parseOneLine( res.alarm.gjdj);
            res.alarm.statusValue = oneLineValue[1];
            if ( res.alarm.jcz == 0) {
              if (oneLineValue[0] == 1) {
                //越上限
                res.alarm.valueFormat = "大于" +  res.sensor.maxValue;
              } else if (oneLineValue[0] == 3) {
                //越下限
                res.alarm.valueFormat = "小于" +  res.sensor.minValue;
              }
            }else {
              res.alarm.valueFormat = res.alarm.jcz;
            }

            this.$store.commit('SET_ALARM_DEVICE', {
              deviceInfo: res.device,
              alarmInfo: res.alarm,
              sensorInfo: res.sensor
            })
        // 刷新实时告警表格
        await this.$store.dispatch('GET_WARNING_DATA', {
          body: {
            closed: 1,
            page: 1,
            alarmType: 1,
            pageSize: 100,
          }, type: 1
        });
        return  true;
      }else {
        return  false;
      }
    },
    // device 设备信息 show 是否显示overlay弹框 status区分告警和正常overlay的位置
    mapMove(device, show, status, isMove){
      if (isMove){
        this.bmCenter.lat = device.lat;
        this.bmCenter.lng = device.lng;
        this.overlayDeviceInfo = {lat: device.lat, lng: device.lng, deviceId: device.deviceId, sbzt: device.sbzt, status: status};
        this.$refs.overlay.showOverlay(show, this.overlayDeviceInfo);
      }else {
        this.overlayDeviceInfo = {lat: device.lat, lng: device.lng, deviceId: device.deviceId, sbzt: device.sbzt, status: status};
        console.log(this.overlayDeviceInfo)
        this.$refs.overlay.showOverlay(show, this.overlayDeviceInfo);
      }
    },

    getOverlay(deviceId){
      this.pointData.some(item => {
        if (item.deviceInfo.deviceId == deviceId){
          let img = new Image();
          img.src = require("../../../../../images/red.png");
          item.icon = img;
          item.deviceInfo.sbzt = 4;
        }
      })
    },
  },

  watch:{
    sbzt: {
      handler(newVal){
        console.log('sbzt',newVal)
        // 点击切换按钮关闭弹框
        this.$refs.overlay.showOverlay(false);
        let data = this.getMapDevicePoint(newVal);
        this.mapVDataset.set(data);

      }
    },
    '$store.getters.settingInfo':{
      handler(newVal) {
        if (newVal.lat && newVal.lng){
          this.bmCenter.lat = parseFloat(newVal.lat);
          this.bmCenter.lng = parseFloat(newVal.lng);
        }
      },
      deep: true
    },

  },
   mounted(){
    // 监听eventBus
    bus.$on('mapMove', device => {
      this.$store.commit('SET_OVERLAY_CARD_TYPE', 0);
      this.mapMove(device, true, 0, true)
    });
    bus.$on('requestAlarm', async deviceId => {
      console.log('requestAlarm', deviceId)
      // 需要地图初始化成功才可以调用之后的方法
      if (this.isInit){
        let flag = await this.getDeviceAlarmInfo(deviceId,true)
        if (flag){
          // 设置为红色告警弹框
          this.$store.commit('SET_OVERLAY_CARD_TYPE', 1);
          // 改变overlay的图标
          this.getOverlay(deviceId);
          // 设置告警弹框无法通过点击消除 需要解除警告才可以查看其他设备信息
          this.alarmShow = true;
          // 设置告警声音
          bus.$emit('alarmPlay')
        }
      }
    });
    bus.$on('closeOverlay', res => {
      this.$refs.overlay.showOverlay(res);
      this.alarmShow = false;
    })
  },
  destroyed(){
    bus.$off(['searchMove','requestAlarm','closeOverlay']);
    clearInterval(this.timer);
    clearInterval(this.interval);
    // 清除对象
    this.mapVDataset = null;
    // this.mapVLayer.destroy();
    this.BMapCore = null;
    this.map = null;
    this.mapGeo = null;
    this.deviceMapLocation = null;
    this.pointData = null;
  }
}
</script>

<style scoped >
.baidumap {
  width: 100% !important;
  height: calc(100% - 70) !important;
}
</style>
