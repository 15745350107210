import Vue from 'vue'
import App from './App.vue'
// import "@/element-ui";
import router from "./router";
import '@/assets/global.css'

// 按需引入
import "@/demand/elementui"
// 引入echarts
import echarts from '@/demand/echarts'
Vue.prototype.$echarts = echarts;
import  '@/demand/vxe'

// Vue.use(vxeTable)
// 引入vue-baidu-map
// import BaiduMap from "vue-baidu-map";
// Vue.use(BaiduMap, {
//     ak: "24f3bdd402e7918543d1d5582b14183d",
// });


import VueAMap from '@vuemap/vue-amap';
import '@vuemap/vue-amap/dist/style.css'
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
    key: '9d22c370830d44033f05dd80db8b6762',
});
// 引入vue-bmap-gl
// import VueBMap from 'vue-bmap-gl';
// import 'vue-bmap-gl/dist/style.css'
// import VueMapvgl  from 'vue-mapvgl';
// Vue.use(VueBMap);
// Vue.use(VueMapvgl);
// VueBMap.initBMapApiLoader({
//     ak: '24f3bdd402e7918543d1d5582b14183d',
//     // 默认百度 sdk 版本为 1.0
//     v: '2.0'
// });


import store from  '@/store/index'

Vue.config.productionTip = false
new Vue({
    router,
    store,
    render: h => h(App),
    data: {
        eventHub: new Vue()
    }
}).$mount('#app')
