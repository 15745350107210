export const apis = {
    deviceHistory: '/detectorValue/device/{deviceId}/history/{timeStart}/{timeEnd}',

    // DEVICE
    deviceState: '/device/state', //登录用户查询自己的设备的状态统计数据，无参
    deviceDistributed: '/device/distributed', //登录用户查看自己设备的区域分布 ，无参
    sensor: '/device/sensor',
    alarmCause: '/JkAlarmCause',
    sensorTpl: '/device/sensor/tpl',
    sensorTplCopy: '/device/sensor/tpl/copy',
    TpLDetail: '/device/sensor/tpl/detail',
    model: '/device/model',
    payModel: '/device/model/payTpl',
    modelTpl: '/device/model/tpl',
    modelTaskResultTpl: '/device/model/task/result/tpl',
    saveModelTpl: '/modelTpl/model/tpl',
    sensorModelBin: '/modelBin/save/bin',
    queryModelBin: '/modelBin/',
    deviceType: '/device/type',
    type: '/device/type',
    device: '/device',
    deviceById: '/device/{id}',
    device_list: '/device/list',
    device_lists: '/device/lists',
    moinMonitorId_list: '/device/notMoinMonitorId/list',
    device_comUser_list: '/device/comUser/list',
    device_monitor_list: '/device/findMonitorList',
    deviceSensor: '/device/device_sensor',
    deviceMapLocation: '/device/map/locations',
    regionDeviceType: '/device/region/type', //获取区域类设备的信息
    regionDeviceStatistic: '/device/region/statistic', //区域设备统计信息
    deviceRealTimeData: '/device/real-time-data', //设备实时数据接口
    deviceChart: '/device/statistic/sensor', //历史告警列表
    eqSysType: '/device/base/eq-sys', //设备系统类型
    sensorType: '/sensor/type', //获取传感器类型
    deviceTop: '/device/region/top',
    deviceAllIds: '/device/find_device_ids',
    regionEqSys: '/device/base/eq-sys/region', //通过区域获取设备类型
    regionAlarm: '/alarm/region', //区域获取告警
    detectorValue: '/detectorValue/device/{deviceId}', //获取某个设备的实时数据
    detectorValueByTime: '/detectorValue/device/{deviceId}/history/{timeTag}', //获取某个设备的实时数据
    deviceAlarmLatest: '/device/alarm/latest',  // 获取单个设备最新告警信息

    JkComBuilding: '/JkComBuilding',
    JkComBuilding_user: '/JkComBuilding/user',
    //REGION
    userLogin: '/sys/login',
    isXWD: '/sys/user/type',
    modifiedUsers: '/sys/user/save_reset_pwd',
    resetListBody: '/sys/user/list_reset_pwd',
    modifyPwd: '/sys/user/reset_pwd',
    govRegion: '/region/gov',
    govRegionAddress: '/region/gov/address',
    region: '/sys/sysregion/list', //获取区域
    regionLevel1: '/sys/sysregion/level1', //获取区域第一级
    regionLevel2: '/sys/sysregion/level2', //获取区域第二级
    regionLevel3: '/sys/sysregion/level3', //获取区域第三级
    regionLevel4: '/sys/sysregion/level4', //获取区域第四级
    regionLevel5: '/sys/sysregion/level5', //获取区域第五级
    regionLevel6: '/sys/sysregion/level6', //获取区域第六级
    regionSave: '/sys/sysregion/save', //获取区域
    regionGrant: '/sys/sysregion/grant', //给用户赋值
    regionRevoke: '/sys/sysregion/revoke', //给用户赋值
    existRegionLevel: '/sys/sysregion/exist-level', //查看某d个用户某个区域是否有数据
    region4With2User: '/sys/viewregion4/list2', //获取两个用户的区域详情
    region5With2User: '/sys/viewregion5/list2', //获取两个用户的区域详情
    region6With2User: '/sys/viewregion6/list2', //获取两个用户的区域详情
    region4List: '/sys/viewregion4/list', //获取子区域包括单位信息
    region5List: '/sys/viewregion5/list',
    region6List: '/sys/viewregion6/list',
    region4Com: '/sys/viewregion4com/list', //获取4级区域的单位信息
    region5Com: '/sys/viewregion5com/list',
    region6Com: '/sys/viewregion6com/list',
    region4Device: '/sys/viewregion4device/list', //获取4级区域的设备信息
    region5Device: '/sys/viewregion5device/list',
    region6Device: '/sys/viewregion6device/list',

    region1statistic: '/sys/sysregion/level1',
    region2statistic: '/sys/sysregion/level2',
    region3statistic: '/sys/sysregion/level3',
    region4statistic: '/sys/sysregion/level4',
    region5statistic: '/sys/sysregion/level5',
    region6statistic: '/sys/sysregion/level6',
    regionLevelStatistic: '/sys/sysregion/level/statistic',
    regionCount: '/sys/sysregion/regionCount',
    regionCountComUser: '/sys/sysregion/regionCount_com_user',
    regionSysCount: '/sys/sysregion/sys_count',

    regionTopWithCom: '/sys/region/top/com',
    sysUserComAssoc: '/sys/company/user', //关联用户和公司  method post delete
    //SYS
    aliOss: '/sys/oss/upload', //阿里云OSS上传地址
    companyMain: '/sys/company/main', //用户企业的主企业信息  修改企业修改调company，修改用户主企业调 /sys/user/save
    company: '/sys/company', //企业
    companyUser: '/sys/company/user', //企业用户
    changePwd: '/sys/user/password',
    userMyCreate: '/sys/user/mycreate', //获取我创建的用户
    userByComUser: '/sys/user/com', //获取我创建的用户

    plan: '/region/plan', //图纸
    plan_zinger: '/region/plan/zinger', //通过大区域ID获取图纸的信息
    plan_region_device: '/region/plan/device', //获取区域的平面图设备关联的信息

    notice: '/notice', //系统公告
    notice_list: '/notice/list', //系统公告
    alarmDevice: '/alarm/device', //获取设备的告警信息
    alarmDevice2: '/alarm/device2', //获取设备的告警信息
    alarmUser: '/alarm/user', //获取用户的告警信息
    alarmUserCount: '/alarm/user/count', //获取用户的告警信息
    alarmClose: '/alarm/close', //关闭用户告警
    verifyAlarm: '/alarm/verify_alarm', //审核告警

    userRegionPlan: '/sys/viewuserplan',
    manufacturer: '/device/base/manufacturer', //生产厂家

    article: '/JkArticle',

    //dashboard
    dashboard: '/statistics/dashboard', //数据报表统计页面
    dashboardAlarm: '/statistics/dashboard/alarm', //数据报表统计页面

    //user
    cancel_reset_pwd: '/sys/user/cancel_reset_pwd',

    // monitorDevice
    saveOrUpdateMonitorDevice: '/monitordevice', // 添加监控设备或查询list
    fetcNotBindinghMonitorDevice: '/monitordevice/notBindingDevice/list', // 获取未绑定的监控设备
    deleteMonitorDevice: '/monitordevice/delete/', // 删除监控设备
    monitorDeviceVedioList: '/monitordevice/historyVedio/list', //获取设备的视频记录
    fetchDeviceMonitorInfo: '/monitordevice/device/monitorinfo', //获取设备的视频设备信息


    // 巡检任务
    patroltask: '/patroltask',
    patroltaskSave: '/patroltask/save',
    patrolDeviceList: '/patroltask/devicelist',
    patrolUserList: '/patroltask/userlist',
    taskrecord: '/taskrecord',
    taskorder: '/taskorder',
    taskDevice: '/taskorder/taskdevice',
    taskTpl: '/JkTaskResultTpl',
    taskTplDetail: '/JkTaskResultTplDetail',
    deviceModelTaskRelation: '/device/deviceModelRelationTaskTpl',
    JkModelTaskTpl:"/JkModelTaskTpl",
    JkModelTaskTplDelete:"/JkModelTaskTpl/delete",

    // 实时视频
    realVedioStart: 'transcoding/start', // 打开实时视频
    stopVedio: 'transcoding/stop/', //关闭实时视频

    subOrder: 'device/order/list',
    subEmail: 'device/order/invoice/sendEmail',

    // 大屏控制台设置
    displaySystemSetting: '/display',

    // 根据comId获取单位下设备
    displayComDevice: '/device/comUser/list',
    deviceMonitorById: '/monitordevice/device/deviceById'
}
