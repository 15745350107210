<template>
  <section>
    <div class="font-style">
      <div class="dev-title">设备总量</div>
      <div class="dev-size">{{ deviceNum.total }}</div>
      <div class="dev-num">台</div>

    </div>

    <div class="dev-total">
      <div class="dev-total-box">
        <img src="../../../../../images/icon-1.png" class="dev-status-icon"/>
        <div class="font-box">
          <span class="dev-status-font1" style="color:#4FC164">正常</span>
          <span class="dev-status-font2">{{ deviceNum.normal }} <span class="dev-status-font2-2">台</span></span>
        </div>
      </div>
      <div class="dev-total-box">
        <img src="../../../../../images/icon-2.png" class="dev-status-icon"/>
        <div class="font-box">
          <span class="dev-status-font1" style="color:#EF6A6E">告警</span>
          <span class="dev-status-font2">{{ deviceNum.warning }} <span class="dev-status-font2-2">台</span></span>
        </div>
      </div>
      <div class="dev-total-box">
        <img src="../../../../../images/icon-3.png" class="dev-status-icon"/>
        <div class="font-box">
          <span class="dev-status-font1" style="color:#FF8256">故障</span>
          <span class="dev-status-font2">{{ deviceNum.fault }} <span class="dev-status-font2-2">台</span></span>
        </div>
      </div>
      <div class="dev-total-box">
        <img src="../../../../../images/icon-4.png" class="dev-status-icon"/>
        <div class="font-box">
          <span class="dev-status-font1" style="color:#5699FF">休眠</span>
          <span class="dev-status-font2">{{ deviceNum.sleep }} <span class="dev-status-font2-2">台</span></span>
        </div>
      </div>

      <div class="dev-total-box">
        <img src="../../../../../images/icon-6.png" class="dev-status-icon"/>
        <div class="font-box">
          <span class="dev-status-font1" style="color:#949494">未知</span>
          <span class="dev-status-font2 ">{{ deviceNum.unknown }} <span class="dev-status-font2-2 ">台</span></span>
        </div>
      </div>
      <div class="dev-total-box" v-if="offlineShow">
        <img src="../../../../../images/icon-5.png" class="dev-status-icon"/>
        <div class="font-box">
          <span class="dev-status-font1" style="color:#99B0EF">掉线</span>
          <span class="dev-status-font2">{{ deviceNum.offline }} <span class="dev-status-font2-2 hidden-font-none">台</span></span>
        </div>
      </div>
      <div class="dev-total-box"  v-if="!offlineShow">
        <img src="../../../../../images/icon-7.png" class="dev-status-icon"/>
        <div class="font-box">
          <span class="dev-status-font1" style="color:#949494">总数</span>
          <span class="dev-status-font2 ">{{ deviceNum.total }} <span class="dev-status-font2-2 ">台</span></span>
        </div>
      </div>
    </div>


    <div class="line-style">
      <div
          class="line-style-on"
          :style="{width: `${formatPercentage((deviceNum.total - deviceNum.offline),deviceNum.total)}%` }"

      >
        <div v-show="formatPercentage((deviceNum.total - deviceNum.offline),deviceNum.total) > 40">
          <span class="line-on-text">在线率</span>
          <span>{{ formatPercentage((deviceNum.total - deviceNum.offline),deviceNum.total) }}%</span>
        </div>

      </div>
      <div
          class="line-style-off"
          :style="{width: `${formatPercentage((deviceNum.offline),deviceNum.total)}%`}"
      >


      </div>
    </div>
    <div class="online-total">
      <div class="online-percentage">
        <span></span>
        <span>在线率</span>
        <span>{{ formatPercentage((deviceNum.total - deviceNum.offline),deviceNum.total) }}%</span>
      </div>
      <div class="offline-percentage">
        <span></span>
        <span>掉线率</span>
        <span>{{ formatPercentage((deviceNum.offline),deviceNum.total) }}%</span>
      </div>
    </div>
  </section>

</template>

<script>

export default {
  name: 'StatDevice',
  props: {
    msg: String
  },
  data() {
    return {
      region:{
        id: 0,
        actualRegionLevel: 0,
      },
      comId:localStorage.getItem('comId'),
      createType:localStorage.getItem('createType'),
    }
  },
  computed:{
    deviceNum(){
      return this.$store.getters.deviceNum
    },
    offlineShow(){
      return this.$store.getters.commonSetting.offlineShow
    }
  },
  mounted() {
  },
  methods:{
    formatPercentage(num,total){
      // if ((num/total).toFixed(2) == 0.14) return 14;
      return ((num / total) * 100 ).toFixed(1);
    }
  }
}
</script>

<style scoped>
  /* 1 */
  .font-style{
    height: 40px;
    color:white;
    background:rgba(255,255,255,0.1);
    width:100%;
    letter-spacing: 2px;
    border-radius: 5px;
    font-size: 14px;
    padding-left: 22px;
    display: flex;
  }
  .dev-title{
    font-size: 14px;
    line-height: 40px;
  }
  .dev-size{
    font-size: 24px;
    padding: 0 10px 0 24px;
    line-height: 40px;
    font-weight: 600;
    /*line-height: 40px;*/
  }
  .dev-num{
    font-size: 14px;
    line-height: 46px;
    font-weight: 600;
  }


  /* 2 */

  .dev-total{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2,50px);
    grid-column-gap: 10px;
    grid-row-gap: 50px;
  }
  .dev-total-box{
    width: 100%;
    height: 100%;
    display: flex;
  }
  .dev-total-box img{
    width: 43px;
    height: 43px;
  }
  .font-box{
    display: flex;
    flex-direction: column;
    padding-left: 12px;
  }
  .dev-status-font1{
    font-size: 14px;
  }
  .dev-status-font2{
    font-size: 16px;
    margin-top: 5px;
    color: #fff;
    font-weight: bold;
  }
  .dev-status-font2-2{
    color:#fff;
    font-size:10px;
  }
  .dev-status-row{
    overflow: hidden;
    /*margin-left: 10px!important;*/
  }
  section > div:nth-child(2){
    margin-top: 50px;
  }
  section > div:nth-child(3){
    margin: 58px 0 0 0;
  }

  /* 3 */
  .line-style{
    width: 100%;
    height: 43px;
    /*background: #000;*/
    display: flex;
  }
  .line-style-on{
    height: 100%;
    background: linear-gradient(90deg,#4fc164, #62c1a2);
    box-shadow: 5px 0px 12px 0px rgba(36,204,184,0.44);
    border-radius: 4px 0px 0px 4px;
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    line-height: 43px;
  }
  .line-style-on span{
    margin-left: 10px;
    margin-right: 15px;
  }

  .line-style-off{
    height: 100%;
    background: #586a8c;
    border-radius: 0px 4px 4px 0px;
    box-shadow: 5px 0px 12px 0px #222222;
  }

  .online-total{
    margin-top: 16px;
    font-size: 12px;
    display: flex;
    color: #ffffff;
  }
  .online-percentage, .offline-percentage{
    width: 160px;
    /*padding-right: 70px;*/
  }
  .online-percentage span:first-child,.offline-percentage span:first-child{
    margin-right: 7px;
    display: inline-block;
    width: 6px;
    height: 6px;
    opacity: 1;
    background: #4fc164;
    border-radius: 50%;
  }
  .offline-percentage span:first-child{
    background: #586a8c;
  }
  .online-percentage span:last-child, .offline-percentage span:last-child{
    margin-left: 16px;
  }

  @media screen and (max-width: 1000px){
    .line-on-text{
      display: none;
    }
  }
  @media screen and (max-width: 1400px){
    .dev-total{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3,50px);
      grid-column-gap: 30px;
      grid-row-gap: 50px;
    }
  }
</style>
