
import {axiosServer,} from '../judge'
import {method,} from '../method'
import {parserResult,} from '../parser'
import {apis,} from '../urls'
export function getRegionCount(params) {
	return axiosServer({
		url: apis.regionCount,
		type: method.GET,
		params: params
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}
export function getComDevice(params) {
	return axiosServer({
		url: apis.device_comUser_list,
		type: method.GET,
		params: params
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

export function getRegionCountComUser(params) {
	return axiosServer({
		url: apis.regionCountComUser,
		type: method.GET,
		params: params
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

export function getRegionSysCount(params) {
	return axiosServer({
		url: apis.regionSysCount,
		type: method.GET,
		params: params
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

export function getRegionEqSys(regionId) {
	let param = { regionId: regionId }
	return axiosServer({
		url: apis.regionEqSys,
		type: method.GET,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

export function getDeviceRegionTop() {
	return axiosServer({
		url: apis.deviceTop,
		type: method.GET
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

export function getRegionLevelStatistic(param) {
	return axiosServer({
		url: apis.regionLevelStatistic,
		type: method.GET,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

export function getL1RegionDeviceStatistics() {
	return axiosServer({
		url: apis.region1statistic,
		type: method.GET
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}
export function getDeviceIds() {
	return axiosServer({
		url: apis.deviceAllIds,
		type: method.GET
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

export function getUserDeviceDistributed() {
	return axiosServer({
		url: apis.deviceDistributed,
		type: method.GET
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

export function getUserDeviceState() {
	return axiosServer({
		url: apis.deviceState,
		type: method.GET
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

/**
 *
 *
 * @param params   deviceId 设备ID  startCreateTime 开始时间 endCreateTime 结束时间
 * @returns {Promise<T | never>}
 */
export function getDeviceSensorHistory(params) {
	return axiosServer({
		url: apis.detectorValueByTime.replace('{deviceId}', params.deviceId).replace('{timeTag}', params.time),
		type: method.GET
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

export function getDeviceValue(params) {
	return axiosServer({
		url: apis.detectorValue.replace('{deviceId}', params),
		type: method.GET
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

/**
 * 获取设备系统类型
 * @param param
 * @returns {Promise<T | never>}
 */
export function getEqSysType(param) {
	return axiosServer({
		url: apis.eqSysType,
		type: method.GET,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

export function saveEqSysType(param) {
	return axiosServer({
		url: apis.eqSysType,
		type: method.POST,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

export function delEqSysType(param) {
	return axiosServer({
		url: apis.eqSysType,
		type: method.DELETE,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

/**
 * 获取生产厂家
 * @param param
 * @returns {Promise<T | never>}
 */
export function getmanufacturer(param) {
	return axiosServer({
		url: apis.manufacturer,
		type: method.GET,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

export function savemanufacturer(param) {
	return axiosServer({
		url: apis.manufacturer,
		type: method.POST,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

export function delmanufacturer(param) {
	return axiosServer({
		url: apis.manufacturer,
		type: method.DELETE,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

/**
 * 获取设备的统计数据
 * @param param
 * @returns {Promise<T | never>}
 */
export function getDeviceRealTimeData(param) {
	return axiosServer({
		url: apis.deviceRealTimeData,
		type: method.GET,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

/**
 * 获取设备的统计数据
 * @param param
 * @returns {Promise<T | never>}
 */
export function getRegionDeviceStatistics(param) {
	return axiosServer({
		url: apis.regionDeviceStatistic,
		type: method.GET,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

/**
 * 根据region id 获取设备类型、
 *
 * @param param
 * @returns {Promise<T | never>}
 */
export function getRegionDeviceType(param) {
	return axiosServer({
		url: apis.regionDeviceType,
		type: method.GET,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

export function getSensorType(param) {
	return axiosServer({
		url: apis.sensorType,
		type: method.GET,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

export function saveSensorType(param) {
	return axiosServer({
		url: apis.sensorType,
		type: method.POST,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

export function delSensorType(param) {
	return axiosServer({
		url: apis.sensorType,
		type: method.DELETE,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

export function getSensor(param) {
	return axiosServer({
		url: apis.sensor,
		type: method.GET,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

/**
 * 添加，修改sensor
 * @param param
 */
export function saveSensor(param) {
	return axiosServer({
		url: apis.sensor,
		type: method.POST,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

/**
 * 删除sensor
 * @param param
 */
export function deleteSensor(param) {
	return axiosServer({
		url: apis.sensor,
		type: method.DELETE,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

/////////////////////  传感模板相关
export function getSensorTpl(param) {
	return axiosServer({
		url: apis.sensorTpl,
		type: method.GET,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

export function saveSensorTpl(param) {
	return axiosServer({
		url: apis.sensorTpl,
		type: method.POST,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

export function deleteSensorTpl(param) {
	return axiosServer({
		url: apis.sensorTpl,
		type: method.DELETE,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

export function copySensorTpl(param) {
	return axiosServer({
		url: apis.sensorTplCopy,
		type: method.POST,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}
export function saveModelBin(param) {
	return axiosServer({
		url: apis.sensorModelBin,
		type: method.POST,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}
export function queryModelBin(param) {
	return axiosServer({
		url: apis.queryModelBin,
		type: method.GET,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

/////////////////////传感模板信息

export function getTpLDetail(param) {
	return axiosServer({
		url: apis.TpLDetail,
		type: method.GET,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

export function saveTpLDetail(param) {
	return axiosServer({
		url: apis.TpLDetail,
		type: method.POST,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

export function deleteTpLDetail(param) {
	return axiosServer({
		url: apis.TpLDetail,
		type: method.DELETE,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

//////////////////////////设备类型

export function getDeviceType(param) {
	return axiosServer({
		url: apis.deviceType,
		type: method.GET,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

export function saveDeviceType(param) {
	return axiosServer({
		url: apis.deviceType,
		type: method.POST,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

export function deleteDeviceType(param) {
	return axiosServer({
		url: apis.deviceType,
		type: method.DELETE,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

//////////////////////////设备型号
export function getModel(param) {
	return axiosServer({
		url: apis.model,
		type: method.GET,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}
export function getPayModel(param) {
	return axiosServer({
		url: apis.payModel,
		type: method.GET,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}
export function savePayModel(param) {
	return axiosServer({
		url: apis.payModel,
		type: method.POST,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}
export function getmodelTpl(param) {
	return axiosServer({
		url: apis.modelTpl,
		type: method.GET,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}
export function savemodelTpl(param) {
	let url = apis.saveModelTpl + '?manuName=' + param.manuName
	return axiosServer({
		url: url,
		type: method.POST,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}
export function deleteModelTpl(param) {
	return axiosServer({
		url: apis.saveModelTpl,
		type: method.DELETE,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

export function saveModel(param) {
	return axiosServer({
		url: apis.model,
		type: method.POST,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

export function deleteModel(param) {
	return axiosServer({
		url: apis.model,
		type: method.DELETE,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

////////////////////////////设备信息

export function getDevice(param) {
	return axiosServer({
		url: apis.device_list,
		type: method.GET,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

export function getDevices(param) {
	return axiosServer({
		url: apis.device_lists,
		type: method.GET,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

export function getDeviceById(param) {
	return axiosServer({
		url: apis.deviceById.replace('{id}', param) + '/display',
		type: method.GET
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

export function getDeviceList(param) {
	return axiosServer({
		url: apis.device_list,
		type: method.GET,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

export function getNotMonitorDeviceList(param) {
	return axiosServer({
		url: apis.moinMonitorId_list,
		type: method.GET,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

export function getMonitorDeviceList(param) {
	return axiosServer({
		url: apis.device_monitor_list,
		type: method.GET,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

export function saveDevice(param) {
	return axiosServer({
		url: apis.device,
		type: method.POST,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

export function deleteDevice(param) {
	return axiosServer({
		url: apis.device,
		type: method.DELETE,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

//device sensor
export function getDeviceSensor(param) {
	return axiosServer({
		url: apis.deviceSensor,
		type: method.GET,
		params: param
	})
		.then((resp) => {
			// console.log(resp)
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

//device sensor
export function getDeviceMap(param) {
	return axiosServer({
		url: apis.deviceMapLocation,
		type: method.GET,
		params: param
	})
		.then((resp) => {
			// console.log(resp)
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}
// 获取单个设备最新的告警信息
export function getDeviceAlarmInfoLatest(param) {
	return axiosServer({
		url: apis.deviceAlarmLatest,
		type: method.GET,
		params: param
	})
		.then((resp) => {
			// console.log(resp)
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

// 获取单位下的设备
export function getDeviceByComId(param){
	return axiosServer({
		url: apis.displayComDevice,
		type: method.GET,
		params: param
	}).then((resp) => {
		return Promise.resolve(parserResult(resp))
	}).catch((e) => {
		return Promise.reject(e)
	})
}

// 获取设备联动视频
export function getDeviceVideoByComId(param){
	return axiosServer({
		url: apis.deviceMonitorById,
		type: method.GET,
		params: param
	}).then((resp) => {
		return Promise.resolve(parserResult(resp))
	}).catch((e) => {
		return Promise.reject(e)
	})
}
