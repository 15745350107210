<template>
  <el-dialog
      :visible.sync="show"
      width="565px"
      custom-class="setting"
      :close-on-click-modal="false"

      :before-close="close"
  >
    <div class="setting-text">
      管理台设置
    </div>
    <div class="setting-form">
      <el-form label-position="top" :model="settingForm" :rules="settingRule" ref="settingForm">
        <el-form-item label="管理台LOGO图标" prop="logo">
          <el-upload
              class="avatar-uploader"
              :action="action"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
          >
            <img v-if="settingForm.logo" :src="settingForm.logo" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>

          </el-upload>
          <div class="text-warning">
            <div>上传jpg、png图片格式；</div>
            <div>建议图片大小在500KB以内；</div>
          </div>
        </el-form-item>
        <el-form-item label="所属单位/地区" prop="regionName">
          <el-input v-model="settingForm.regionName" placeholder="请输入管理台所属单位或区域"></el-input>
        </el-form-item>
        <el-form-item label="经度设置" prop="lng">
          <el-input v-model="settingForm.lng" placeholder="请输入经度"></el-input>
        </el-form-item>
        <el-form-item label="纬度设置" prop="lat">
          <el-input v-model="settingForm.lat" placeholder="请输入纬度"></el-input>
        </el-form-item>
        <el-form-item label="管理台名称" prop="cmsName">
          <el-input v-model="settingForm.cmsName" placeholder="请输入管理台名称"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="setting-btn">
      <el-button type="primary" @click="cancel">取消</el-button>
      <el-button type="primary" @click="confirm">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {Message} from 'element-ui'
import {axiosServer} from "@/api/judge";
import baseUrl from "@/api/baseUrl";
import {getSystemSettingDetails} from "@/api/impl/sys";

export default {
  name: "SettingDialog",
  props:{
    show: Boolean,
  },
  data() {
    return {
      // 弹框显示隐藏初始化
      dialogVisible: true,
      coverUrl: null,
      // 表单初始化
      settingForm: {
        regionName: null,
        logo: null,
        cmsName: null,
        lat: null,
        lng: null
      },
      logoUrl: 'http://file.jckjcn.com/file/20210721/6439d5d9991c401c825c9e761a1d34ea.png',
      settingPage: {
        userId: localStorage.getItem('userId'),
        page: 1,
        pageSize: 1
      },
      settingRule: {
        // regionName: [
        //   { required: true, message: "控制台名称不能为空", trigger: "blur" }
        // ],
        // logo: [
        //   { required: true, message: "图片不能为空", trigger: "blur" }
        // ],
        // cmsName: [
        //   { required: true, message: "公司名称不能为空", trigger: "blur" },
        // ]
      },
      // 上传地址
      action: '',
      isUpload: false
    }
  },
  computed:{
    settingInfo(){
      return this.$store.getters.settingInfo;
    }
  },
  methods: {
    // 上传图片成功回调方法
    handleAvatarSuccess(res, file) {
      this.isUpload = true;
      console.log('res:', res);
      console.log('file:', file);
      console.log('---上传成功---')
      if (res.code == 0){
        this.settingForm.logo = res.url;
      }

    },

    // 图片上传限制
    beforeAvatarUpload(file) {
      let isJPG = ['image/png', 'image/jpg', 'image/jpeg'].indexOf(file.type) > -1 ? true : false;
      const isLt2M = file.size / 1024 / 1024 <= 1;
      if (!isJPG) {
        Message({
          showClose: true,
          message: '请上传jpg、png图片格式',
          type: 'error'
        })
      }
      if (!isLt2M) {
        Message({
          showClose: true,
          message: '图片上传尺寸不能大于1MB',
          type: 'error'
        })
      }
      this.settingForm.logo = URL.createObjectURL(file)
      return isJPG && isLt2M;
    },

    // 关闭按钮
    close() {
      this.$emit('changeShow', 1)
      // for (const key in this.settingForm) {
      //   this.settingForm[key] = null;
      // }
    },

    // 取消按钮
    cancel() {
      // 弹框隐藏
      this.$emit('changeShow', 1)
    },

    // 确定按钮
    confirm() {
      console.log(this.isUpload)
      if (!this.isUpload){
        console.log("===执行===")
        this.settingForm.logo = this.logoUrl
      }
      // 提交表单
      this.$refs["settingForm"].validate(valid => {
        if (valid){
          axiosServer({
            url: '/display',
            type: 'POST',
            params: {
              id: localStorage.getItem('id'),
              userId: localStorage.getItem('userId'),
              ...this.settingForm
            }
          }).then(res => {
            console.log(res)
            if (res.data.code == 0){
              this.$store.commit('SET_SETTING_INFO', res.data.entity)
            }

          })
        }
      })
      this.$emit('changeShow', 1)
      // 弹框隐藏
      // this.$emit('changeShow', 1)
    },

  },
  created(){
    // this.getSetting()
    console.log("===created===")
    let path = process.env.NODE_ENV !== 'production' ? '/proxyApi' : baseUrl.BASE_API
    this.action = `${path}/sys/oss/upload?token=${localStorage.getItem('token')}`
    // this.$store.dispatch('GET_SYSTEM_SETTING', this.settingPage);
  },
  mounted() {
    console.log("===mounted===")
    console.log(this.settingInfo)
    this.settingForm.regionName = this.settingInfo.regionName;
    this.settingForm.cmsName = this.settingInfo.cmsName;
    this.settingForm.logo = this.settingInfo.logo || 'http://file.jckjcn.com/file/20210721/6439d5d9991c401c825c9e761a1d34ea.png';
    this.settingForm.lat = this.settingInfo.lat;
    this.settingForm.lng = this.settingInfo.lng;
  },
  watch:{
    settingInfo:{
      handler(newVal){
        this.settingForm.regionName = newVal.regionName;
        this.settingForm.cmsName = newVal.cmsName;
        this.settingForm.logo = newVal.logo || 'http://file.jckjcn.com/file/20210721/6439d5d9991c401c825c9e761a1d34ea.png';
        this.settingForm.lat = newVal.lat;
        this.settingForm.lng = newVal.lng;
      }
    }
  }
}
</script>

<style scoped>
.el-dialog__wrapper{
  width: 100vw;
  height: 100vh;
}
/deep/.el-dialog{
  margin:0 !important;
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  height: 860px;
}
/deep/.el-dialog, .el-pager li{
  background: #17264c;
}
/* 顶部文字区域 */
.setting-text {
  padding-top: 40px;
  text-align: center;
  font-size: 18px;
  color: #fff;
}

/* 表单区域 */
.setting-form {
  margin: 20px 30px 0 30px;
}


.el-form {
  width: 100%;
}

.el-form-item {
  margin-bottom: 20px;
}

/deep/ .el-form-item__label {
  height: 30px;
  line-height: 30px;
  color: #fff;
  margin-bottom: 10px;
  font-size: 18px;
  padding: 0;
}

/deep/ .el-input__inner {
  height: 48px;
  background: #17264c !important;
  border: 1px solid rgba(91, 163, 237, 0.50) !important;
  border-radius: 8px;
}

.avatar-uploader {
  width: 188px;
  height: 188px;
  background: #17264c;
  border: 1px solid rgba(91, 163, 237, 0.50);
  border-radius: 8px;
  box-sizing: border-box;
}

.avatar-uploader-icon {
  font-size: 18px;
  color: #5BA3ED;
  width: 188px;
  height: 188px;
  line-height: 188px;
  text-align: center;
}

.avatar {
  width: 186px;
  height: 186px;
  object-fit: cover;
  object-position: top left;
  border-radius: 8px;
}

/deep/ .el-form > div:nth-child(1) > .el-form-item__content {
  display: flex;

}

.text-warning {
  height: 188px;
  display: flex;
  justify-content: flex-end;
  margin-left: 20px;
  flex-direction: column;
}

.text-warning > div {
  height: 25px;
  line-height: 25px;
  font-size: 18px;
  color: #EAEAEA4D;
  font-weight: 400;
  letter-spacing: 1px;
}

/* 按钮区域 */
.setting-btn {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}

.setting-btn > .el-button:nth-child(1) {
  width: 128px;
  height: 47px;
  opacity: 1;
  border-color: #3e4f77;
  background: #3e4f77;
  border-radius: 4px;
}

.setting-btn > .el-button:nth-child(2) {
  width: 128px;
  height: 47px;
  opacity: 1;
  border-color: #0364cd;
  background: #0364cd;
  border-radius: 4px;
  margin-left: 50px;
}
</style>
<style>
.setting {
  height: 565px;
  border-radius: 7px;
  background: #17264C;
  border: 1px solid #5BA3ED4D;
  /*margin-top: 0!important;*/
  /*position: absolute;*/
  /*top: 50%;*/
  /*left: 50%;*/
  /*transform: translate(-50%, -50%);*/
}

.setting > div:nth-child(1) {
  padding: 0;
}

.setting > div:nth-child(1) .el-dialog__headerbtn {
  font-size: 22px;
}

.setting > div:nth-child(2) {
  padding: 0;
}

.setting > div:nth-child(3) {
  padding: 0;
}

/*弹窗自定义 动画*/
.el-dialog__wrapper {
  transition-duration: 0s;
}

.dialog-fade-enter-active {
  animation: none !important;
}

.dialog-fade-leave-active {
  /*定义 退出时的 动画 过渡时间*/
  transition-duration: 0s !important;
  animation: none !important;
}

.dialog-fade-enter-active .el-dialog,
.dialog-fade-leave-active .el-dialog {
  /*设置 填充模式   此处的值为 当动画完成后，保持最后一个属性值（在最后一个关键帧中定义）*/
  animation-fill-mode: none;
}

.dialog-fade-enter-active .el-dialog {
  /* 进入自定义动画*/
  animation-name: none;
}

.dialog-fade-leave-active .el-dialog {
  /* 退出 自定义动画*/
  animation-name: none;
}

</style>
