<template>
  <el-dialog
      :visible.sync="show"
      width="1000px"
      custom-class="setting"
      :close-on-click-modal="false"

      :before-close="close"
  >
    <div class="setting-text">
      历史告警记录
    </div>
    <div class="search">
      <el-input v-model="searchVal" placeholder="搜索设备编码">
        <template #prefix>
          <img src="../../images/search.png"/>
        </template>
      </el-input>
      <el-button @click="search">查询</el-button>
    </div>
    <div  :class="[recordLoad ? '': 'pdr', 'table']" >
      <HlTable
          :table-data="tableData"
          :column="recordOptions.recordColumn"
          :cell-style="recordOptions.recordCellStyle"
          :head-style="recordOptions.recordHeadStyle"
          color="#17264c"
          :height="`640px`"
          :loading="recordLoad"
          @row-click="recordClick"

      >
        <template #regionName="scope" >
          <div class="hidden-font" :title="scope.row.regionName">{{scope.row.regionName}}</div>
        </template>
      </HlTable>
    </div>
  </el-dialog>
</template>

<script>
import {getUserAlarm} from "@/api/impl/alarm";
import {parseOneLine} from "@/utils/device/DetectorValueParser";
import bus from "@/utils/eventBus/bus";
import {axiosServer} from "@/api/judge";
import HlTable from "@/component/Tabel/HlTable";
import {recordColumn,recordCellStyle,recordHeadStyle} from './index'
export default {
  name: "RecordDialog",
  props:{
    show: Boolean,
  },
  components:{
    HlTable
  },
  data() {
    return {
      // 弹框显示隐藏初始化
      dialogVisible: false,
      // 表单数据
      searchVal: null,
      // 表格数据
      tableData: [],
      // 请求体
      reqBody: {
        closed:  2,
        page: 1,
        pageSize: 20,
        alarmType: 1
      },
      recordOptions:{
        recordColumn,
        recordCellStyle,
        recordHeadStyle
      },
      recordLoad: true
    }
  },

  methods: {
    search(){
      this.reqBody.ip = this.searchVal;
      this.requestData();
    },
    // 关闭按钮
    close() {
      this.$delete(this.reqBody, 'ip');
      // for (const key in searchForm.value) {
      //   searchForm.value[key] = null;
      // }
      this.$emit('changeShow', 5)
      this.searchVal = null;
    },
    // 取消按钮
    cancel() {
      // 弹框隐藏
      this.$emit('changeShow', 5)
    }
    ,
    // 确定按钮
    confirm() {
      // 提交表单
      // 弹框隐藏
      this.$emit('changeShow', 5)
    },
    // 获取历史告警数据
    requestData() {
      this.recordLoad = true
      axiosServer({
        url: "/display/alarm/list/history?page=" + this.reqBody.page + "&pageSize=" + this.reqBody.pageSize,
        type: "GET"
      }).then(res => {
        console.log(res)
        if (res.code == 200){
          this.tableData = res.result.records;
        }
        this.recordLoad = false;
      }).catch(e => {
        this.recordLoad = false;
      })
    },
    async recordClick(e){
      bus.$emit('dealDialog', {show: true, id: e.deviceId, state: 2, tableInfo: e});
    }
  },
  created(){
    this.requestData()
  }
}
</script>

<style scoped>
.el-dialog__wrapper{
  width: 100vw;
  height: 100vh;

}
/deep/.el-dialog{
  height: 798px;
  margin:0 !important;
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
}
/deep/.el-dialog, .el-pager li{
  background: #17264c;
}
/* 顶部文字区域 */
.setting-text {
  padding-top: 40px;
  text-align: center;
  font-size: 18px;
  color: #fff;
}

/* 搜索区域 */

/deep/.search .el-input__inner {
  width: 772px;
  height: 50px;
  opacity: 1;
  background: #17264c !important;
  border: 1px solid rgba(91, 163, 237, 0.50) !important;
  border-radius: 26px;
  padding: 0 50px 0 65px;
  font-size: 14px;
}

.search {
  margin: 20px 30px 20px 30px;
  display: flex;
}

.search-input {
  width: 772px;
  height: 50px;
  opacity: 1;
  background: #17264c;
  border: 1px solid rgba(91, 163, 237, 0.50);
  border-radius: 26px;
}

.search .el-button {
  width: 158px;
  height: 51px;
  opacity: 1;
  color: #fff;
  font-size: 15px;
  border-color: #0364cd;
  background: #0364cd;
  border-radius: 30px
}

/deep/ .el-input__prefix {
  line-height: 62px;
  left: 31px;
}
.pdr{
  padding-left:  17px;
}

</style>
<style>
.warning {
  height: 798px;
  border-radius: 7px;
  background: #17264C;
  border: 1px solid #5BA3ED4D;
  /*margin-top: 0vh !important;*/
  /*position: absolute;*/
  /*top: 50%;*/
  /*left: 50%;*/
  /*transform: translate(-50%, -50%);*/
}

.warning > div:nth-child(1) {
  padding: 0;
}

.warning > div:nth-child(1) .el-dialog__headerbtn {
  font-size: 22px;
}

.warning > div:nth-child(2) {
  padding: 0;
}

.warning > div:nth-child(3) {
  padding: 0;
}

</style>
