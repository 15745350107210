import {getCompany, getSystemSettingDetails} from "@/api/impl/sys";
import {getUserAlarm} from "@/api/impl/alarm";
import {
    parseClientState,
    parseDeviceStatus,
    parseOneLine, parseRealValue,
    parseSensorStatus,
    parseStateValue,
    parseUnit
} from "@/utils/device/DetectorValueParser";
import {getDeviceByComId, getDeviceById, getDeviceSensor, getDeviceSensorHistory} from "@/api/impl/device";
import {axiosServer} from "@/api/judge";
import {apis} from "@/api/urls";

const device = {
    state: {
        // 全局设备Id
        deviceId: null,
        // 设备详细信息卡片显示隐藏
        deviceDetailsShow: false,
        // 公司设备信息卡片显示隐藏
        deviceCompanyShow: false,
        // 设备详细信息卡片展开收缩
        expand: false,
        // 设备统计
        deviceNum: {},
        // 设置设备详细信息
        deviceInfo: {
            deviceName: ''
        },
        sensorRtData: [],
        realtimeData: {},
        deviceSensor: [],
        sensorHistory: [],
        searchDeviceInfo: {},
        // 根据按钮组 地图显示不同设备标签
        sbzt: 0,
        //
        devKind: 0,
        // 地图overlay弹框类型
        overlayType: 0,
        // 地图弹框信息
        deviceAlarm: {
            deviceInfo: {},
            alarmInfo: {},
            sensorInfo: {}
        },
        // 设备详细信息
        deviceAlarmInfo: {
            list: [],
            total: 0
        },
        comDevice: []
    },
    mutations: {
        // 设置设备id
        SET_DEVICE_ID(state, id){
            state.deviceId = id;
        },
        // 设置设备详细信息卡片显示隐藏
        SET_DEVICE_DETAILS_SHOW(state, payload){
            state.deviceDetailsShow = payload;
        },
        SET_DEVICE_COMPANY_SHOW(state, payload){
            state.deviceCompanyShow = payload;
        },
        // 设置设备详细信息卡片展开收缩
        SET_EXPAND(state, payload){
            state.expand = payload;
        },
        // 设置设备统计
        SET_DEVICE_NUM(state, obj){
            state.deviceNum = obj;
        },
        // 设置设备详细信息
        SET_DEVICE_INFO(state, obj){
            state.deviceInfo = Object.assign(state.deviceInfo, obj)
        },
        // 设置设备传感器信息
        SET_SENSOR_RT_DATA(state, arr){
            state.sensorRtData = arr;
        },
        SET_REALTIME_DATA(state, obj){
            state.realtimeData =  obj
        },
        SET_DEVICE_SENSOR(state, arr){
            state.deviceSensor = arr;
        },
        SET_SENSOR_HISTORY(state, arr){
            state.sensorHistory = arr;
        },
        // 设置sbzt值
        SET_SBZT(state, num){
            console.log(num)
            state.sbzt = num;
        },
        SET_DEV_KIND(state, num){
            console.log(num)
            state.devKind = num;
        },
        // 设置弹框类型
        SET_OVERLAY_CARD_TYPE(state, num){
            state.overlayType = num;
        },
        SET_ALARM_DEVICE(state, obj){
            state.deviceAlarm = obj;
        },

        SET_SEARCH_DEVICE_INFO(state, obj){
            state.searchDeviceInfo = Object.assign(state.searchDeviceInfo, obj)
            // state.searchDeviceInfo = obj;
        },

        SET_DEVICE_ALARM_INFO(state, obj){
            state.deviceAlarmInfo.list = obj.list;
            state.deviceAlarmInfo.total = obj.total;
        },
        SET_COM_DEVICE(state, list){
            state.comDevice = list;
        }
    },
    actions: {
        GET_DEVICE_INFO_BY_ID(context, deviceId, unitId){

            //   getDeviceById(id).then(async res => {
            //     if (res.code == 0){
            //         try {
            //             let resp = await getCompany({page:1,pageSize:1,comId: res.device.comId});
            //             console.log('getDeviceById', res);
            //             console.log('getCompany', resp);
            //             if (resp.code == 0){
            //                 let obj = Object.assign(res.device, {phone: resp.page.list[0].phone, contacts: resp.page.list[0].contacts })
            //                 context.commit('SET_DEVICE_INFO', obj)
            //                 console.log('obj===', obj)
            //             }else {
            //                 // 弹出信息框
            //             }
            //         }catch (e){
            //             // 弹出信息框
            //             console.log('getCompany:error', e)
            //         }
            //     }else {
            //         // 弹出信息框
            //     }
            // }).catch(e => {
            //       // 弹出信息框
            //       console.log('getDeviceById:error',e)
            //   })
        },
        GET_SENSOR_RT_DATA_BY_ID(context, obj){
            axiosServer({
                url: "/display/sensor/device/queryById?deviceId=" + obj.deviceId + "&appid=" + obj.appid,
                type: "GET"
            }).then(res => {

                let arr = []
                if (res.code === 200){

                    res.result.map(item => {
                        let value = parseStateValue(item, item.name);
                        let unit = parseUnit(item.code);
                        let obj = {
                            sensorName: item.name,
                            valRegion: `(${item.min}<值<${item.max})`,
                            realVal: parseRealValue(item ,unit, item.name),
                            // realVal: item.value + unit,
                            sbztValue: value
                        };
                        arr.push(obj);
                        context.commit('SET_SENSOR_RT_DATA', arr)
                    })
                }
            })
            // getDeviceSensor({page: 1, pageSize: 64, deviceId: id}).then(async res => {
            //     if (res.code == 0){
            //         try {
            //             let resp = await axiosServer({
            //                 url: apis.detectorValue.replace('{deviceId}', id),
            //                 type: 'GET',
            //             }).then(res => res.data);
            //             console.log('getDeviceSensor', res);
            //             console.log('getDeviceRealtimeData', resp);
            //             if (resp.code == 0){
            //                 let obj = {}
            //                 obj.clientStateValue =  parseClientState(resp.value.clientState);
            //                 obj.sbzt = resp.value.detectorState;
            //                 obj.clientStateValue.indexOf('通讯异常') != -1 ?  obj.statusValue = '--' : obj.statusValue = parseDeviceStatus(resp.value.deviceStatus);
            //                 obj.gateway = resp.value.detectorState == 2 ? '掉线' : '在线';
            //                 obj.sim = resp.value.sim;
            //                 context.commit('SET_DEVICE_INFO', obj);
            //                 parseSensorStatus(resp.value).forEach((item) => {
            //                     let tdh = item[3];
            //                     for (let i = 0; i < res.page.list.length; i++) {
            //                       let realTimeValue = res.page.list[i];
            //                       if (realTimeValue.looperNum == tdh) {
            //                         res.page.list[i].sbztValue = item[1][0];
            //                         res.page.list[i].realValue = item[2];
            //                         res.page.list[i].sbzt = item[1][1];
            //                         res.page.list[i].realVal = res.page.list[i].realValue + res.page.list[i].unit;
            //                         res.page.list[i].valRegion = `(${res.page.list[i].minValue}<值<${res.page.list[i].maxValue})`
            //                         break;
            //                       }
            //                     }
            //                 });
            //                 context.commit('SET_SENSOR_RT_DATA', res.page.list)
            //                 context.commit('SET_REALTIME_DATA', resp.value)
            //             }else {
            //                 // 弹出信息框
            //             }
            //         }catch (e) {
            //             // 弹出信息框
            //         }
            //     }else {
            //         // 弹出信息框
            //     }
            // }).catch(e => {
            //     // 弹出信息框
            // })
        },
        // GET_DEVICE_SENSOR_BY_ID(context, id){
        //     getDeviceSensor({deviceId: id, page: 1, pageSize: 20}).then(res => {
        //         console.log('getDeviceSensor', res);
        //         if (res.code == 0){
        //             context.commit('SET_DEVICE_SENSOR', res.page.list)
        //         }
        //     })
        // },
        // 趋势图
        GET_DEVICE_SENSOR_HISTORY_BY_ID(context,id){
            // getDeviceSensorHistory({deviceId: id, time: 1}).then(res => {
            //     console.log('getDeviceSensorHistory:', res);
            //     if (res.code == 200){
            //         context.commit('SET_SENSOR_HISTORY', res.data)
            //     }
            // })
        },
        GET_MONITOR_DEVICE_BY_COM_ID(context, body){
            // getDeviceByComId(body).then(res => {
            //     if (res.code == 0){
            //         console.log(res)
            //         context.commit('SET_COM_DEVICE', res.page.list)
            //     }
            // })
            axiosServer({
                url: "/display/device/com/list?appid=" + body.appid + "&unitId=" + body.unitId,
                type: "GET",
            }).then(res => {
                context.commit('SET_COM_DEVICE', res.result)
            })
        }
    }
}
export default device
