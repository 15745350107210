<template>
  <!--v-show="LocationCloseTips"-->
<!--  :class="{sample: true, active,  }"-->
  <bm-overlay
      v-show="LocationCloseTips"
      ref="customOverlay"
      pane="labelPane"
      :class="{sample: true, active  }"
      @draw="draw"
  >
    <!--  点击  -->
    <div class="container" v-show="overlayType === 0">
      <div class="title ">
        <div class="left hidden-font" :title="deviceAlarm.deviceInfo.deviceName">{{ deviceInfo.deviceName || '无' }}</div>
        <div class="right" :style="{background: renderBgColor(position.sbzt)}">{{ renderText(position.sbzt) }}</div>
        <!--        <div :style="{backgroundColor: renderBgColor(item.sbzt)}">{{ renderText(item.sbzt) }}</div>-->
      </div>
      <div class="text hidden-font" :title="deviceInfo.regionName">所属单位：{{ deviceInfo.regionName || '无' }}</div>
      <div class="text hidden-font" :title="deviceInfo.detailAddress">详细位置：{{ deviceInfo.detailAddress || '无' }}</div>
      <div class="text hidden-font" :title="deviceInfo.contacts">联系人：{{  deviceInfo.contacts || '无' }}</div>
      <div class="text hidden-font" :title="deviceInfo.phone">联系电话：{{  deviceInfo.phone || '无' }}</div>
      <div class="text hidden-font text-last" :title="deviceInfo.alarmSize">告警总次数：{{   deviceInfo.alarmSize || '0' }}</div>
      <div class="text" @click.stop="openVideo" style="cursor: pointer">查看监控</div>
      <div class="container__arrow container__arrow--bc" ></div>
    </div>
    <!--  告警  -->
    <div class="container-warn" v-show="overlayType === 1">
      <div class="title ">
        <div class="left hidden-font" :title="deviceInfo.deviceName || '无'">{{ deviceAlarm.deviceInfo.deviceName || '无' }}</div>
        <div class="right" >告警</div>
        <!--        <div :style="{backgroundColor: renderBgColor(item.sbzt)}">{{ renderText(item.sbzt) }}</div>-->
      </div>
      <div class="text hidden-font" :title="deviceAlarm.deviceInfo.regionName">所属单位：{{ deviceAlarm.deviceInfo.regionName || '无' }}</div>
      <div class="text hidden-font" :title="deviceAlarm.deviceInfo.detailAddress">详细位置：{{ deviceAlarm.deviceInfo.detailAddress || '无' }}</div>
      <div class="text hidden-font" :title="deviceInfo.contacts">联系人：{{ deviceInfo.contacts || '无' }}</div>
      <div class="text hidden-font" :title="deviceInfo.phone">联系电话：{{ deviceInfo.phone || '无' }}</div>
      <div class="text hidden-font" :title="deviceAlarm.alarmInfo.jcsj">告警时间：{{ deviceAlarm.alarmInfo.jcsj || '无' }}</div>
      <div class="text hidden-font" :title="deviceAlarm.alarmInfo.statusValue">告警类型：{{ deviceAlarm.alarmInfo.statusValue || '无' }}</div>
      <div class="text hidden-font" :title="deviceAlarm.alarmInfo.sensorName">告警传感：{{ deviceAlarm.alarmInfo.sensorName || '无' }}</div>
      <div class="text hidden-font"
           :title="deviceAlarm.alarmInfo.valueFormat + deviceAlarm.sensorInfo.unit"
      >
        告警数据：{{ deviceAlarm.alarmInfo.valueFormat + deviceAlarm.sensorInfo.unit || '无' }}
      </div>

      <div class="text" @click.stop="openVideo" style="cursor: pointer">查看告警视频</div>
      <div class="btn" @click="dealWarning">处理告警</div>
      <div class="container__arrow container__arrow--bc" ></div>
    </div>

    <!--  搜索  -->
    <div class="container" v-show="overlayType === 2">
      <div class="title ">
        <div class="left hidden-font" :title="searchDeviceInfo.deviceName || '无'">{{ searchDeviceInfo.deviceName || '无' }}</div>
        <div class="right" :style="{background: renderBgColor(position.sbzt)}">{{ renderText(position.sbzt) }}</div>
        <!--        <div :style="{backgroundColor: renderBgColor(item.sbzt)}">{{ renderText(item.sbzt) }}</div>-->
      </div>
      <div class="text hidden-font" :title="searchDeviceInfo.regionName">所属单位：{{ searchDeviceInfo.regionName || '无' }}</div>
      <div class="text hidden-font" :title="searchDeviceInfo.detailAddress">详细位置：{{ searchDeviceInfo.detailAddress || '无' }}</div>
      <div class="text hidden-font" :title="deviceInfo.contacts">联系人：{{  deviceInfo.contacts || '无' }}</div>
      <div class="text hidden-font" :title="deviceInfo.phone">联系电话：{{  deviceInfo.phone || '无' }}</div>
      <div class="text hidden-font" :title="deviceInfo.alarmSize">告警总次数：{{   deviceInfo.alarmSize || '0' }}</div>
      <div class="container__arrow container__arrow--bc" ></div>
    </div>

  </bm-overlay>

</template>

<script>

import {getDeviceById, getDeviceSensor, getDeviceSensorHistory} from "@/api/impl/device";
import DealDialog from "@/component/Dialog/DealDialog";
import VideoDialog from "@/component/Dialog/VideoDialog";
import bus from "@/utils/eventBus/bus";
import {axiosServer} from "@/api/judge";
import {parseClientState, parseDeviceStatus, parseOneLine, parseSensorStatus} from "@/utils/device/DetectorValueParser";
import {apis} from "@/api/urls";
import {getAlarm, getAlarm2} from "@/api/impl/alarm";
import {getCompany} from "@/api/impl/sys";
export default {
  components:{
    DealDialog,VideoDialog
  },
  data() {
    return {
      showDangerBtn: true,
      showBubbleBtn: true,
      showLocationAlarm: false,
      LocationLoad: false,
      MultipleDeviceAlarm: false,
      LocationCloseTips: true,
      LocationInfo: {},
      OverlayBoby: {
        page: 1,
        pageSize: 5,
      },
      deviceAlarmDate: [],
      interval: null,
      alarmBody:{
        page: 1,
        pageSize: 20,
        alarmType: 1,
        deviceId: null
      },
      // websocket获取的最新设备告警信息
      warningDeviceInfo: {},
      alarmDeviceInfo: {},
      sensorRtData: [],

    }
  },
  props: ['text', 'position', 'active' , 'status'],
  watch: {
    // 监听vuex中设备id的值 只要设备id发生变化 执行获取设备信息的方法
    "$store.getters.deviceId": {
      handler(newVal) {
        // 清除上一个定时器
        clearInterval(this.interval)
        // 根据设备id请求数据
        this.$store.dispatch('GET_DEVICE_INFO_BY_ID', newVal);
        this.$store.dispatch('GET_SENSOR_RT_DATA_BY_ID', newVal);
        this.$store.dispatch('GET_DEVICE_SENSOR_BY_ID', newVal);
        this.$store.dispatch('GET_DEVICE_SENSOR_HISTORY_BY_ID', newVal)
        this.startARequestRealtimeInterval(newVal);
        this.getAlarmById(newVal)
      }
    },
    deviceInfo:{
      handler(newVal){
        // console.log('-=-=-=-=-=', newVal)
      },
      deep: true
    },
    position: {
      handler() {
        console.log("position 改变了")
        this.$refs.customOverlay.reload()
      },
      deep: true
    },
  },
  computed:{
    deviceId(){
      return this.$store.getters.deviceId;
    },
    deviceInfo(){
      return this.$store.getters.deviceInfo;
    },
    overlayType(){
      return this.$store.getters.overlayType;
    },
    deviceAlarm(){
      return this.$store.getters.deviceAlarm;
    },
    searchDeviceInfo(){
      return this.$store.getters.searchDeviceInfo;
    },

  },
  methods: {
    // 显示overlay颜色
    renderBgColor(sbzt){
      switch (sbzt){
        case 1:
          return '#4fc164';
        case 2:
          return '#a0a0a0';
        case 3:
          return '#EF7818';
        case 4:
          return '#FD3838';
        case 5:
          return '#4E95F7';
      }
    },
    // 显示overlay文字
    renderText(sbzt){
      switch (sbzt){
        case 1:
          return '正常';
        case 2:
          return '掉线';
        case 3:
          return '故障';
        case 4:
          return '告警';
        case 5:
          return '休眠';
      }
    },
    openVideo(){
      console.log(1111)
      bus.$emit('videoDialog', {show: true, id: this.deviceId, state: 0});

    },
    videoDialogClose(){
      this.isVisibleVideo = false;
    },
    // getMultipleDeviceAlarm() {
    //   this.MultipleDeviceAlarm = true
    // },
    //
    // reloadOverlay() {
    //   this.$refs.customOverlay.reload()
    // },


    draw({el, BMap, map}) {
      console.log('draw:this.position',this.position)
      const {lng, lat} = this.position
      const pixel = map.pointToOverlayPixel(new BMap.Point(lng, lat))

      if (this.position.status === 0){
        el.style.left = pixel.x - 112 + 'px'
        el.style.top = pixel.y - 145  + 'px'
      }else {
        el.style.left = pixel.x - 110 + 'px'
        el.style.top = pixel.y  - 325  + 'px'
      }
      // this.position.deviceId 不为空 修改id值 在watch里执行获取设备信息函数
      this.position.deviceId && this.$store.commit('SET_DEVICE_ID', this.position.deviceId)
    },

    leftRegionSelectListener() {
      this.$emit('getLocationClick', true)
    },

    // CloseTips() {
    //   this.LocationCloseTips = false
    // },

    showOverlay(visible, item) {
      console.log("showOverlay")
      this.LocationLoad = true
      this.LocationCloseTips = visible
      this.LocationInfo = item
    },

    // 定时获取实时数据
    startARequestRealtimeInterval(id) {
      this.interval = setInterval(() => {
          this.$store.dispatch('GET_SENSOR_RT_DATA_BY_ID', id)
      },  30 * 1000);
    },

    // 获取设备告警信息
    getAlarmById(id){
      this.alarmBody.deviceId = id;
      this.$store.dispatch('GET_ALARM_DATA', this.alarmBody)
    },

    // 打开处理警告弹框
    dealWarning(event){
      // 阻止点击事件穿透
      event.stopPropagation();
      // state 0处理告警 1实时告警
      bus.$emit('dealDialog', {show: true, id: this.deviceId, state: 0});
    },
  },
  activated() {
    console.log('activated')
  },
  deactivated() {
    console.log('deactivated')
  },
}
</script>

<style >
.tipsLcation .el-icon-close:before {
  font-size: 15px;
  color: black;
  float: right;
}
</style>
<style lang="less" scoped>
.tipsLcation {
  height: 20px;
  float: right;
  background: none;
  border: none;
  margin-top: -5px;
}

.sample {
  height: 50px;
  width: 240px;
  /*background: transparent;*/
  border-radius: 5px;
  /*color: black;*/
  text-align: center;
  font-size: 12px;
  border: none;
  /*box-shadow: 2px 2px 2px 2px #00b3a4;*/
  margin-top: -64px;
  margin-left: -55px;
  position: absolute
}

.prompt {
  font-size: 10px;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 150px;
  margin-top: -16px;
  text-align: left;
}
/* 弹出框 */
//
//.popover{
//  z-index: 10;
//  filter:drop-shadow(-1px 1px 0 #5BA3ED) drop-shadow(1px 1px 0 #5BA3ED) drop-shadow(0 1px 0 #5BA3ED) drop-shadow(1px 0 0 #5BA3ED) drop-shadow(1px -1px 0 #5BA3ED) drop-shadow(-1px -1px 0 #5BA3ED) drop-shadow(0 -1px 0 #5BA3ED) drop-shadow(-1px 0 0 #5BA3ED);
//  position: relative;
//  width: 366px !important;
//  opacity: .91;
//}
//
//.popover::after{
//  content: '';
//  position: absolute;
//  top: 0;
//  left: 0;
//  width: 366px !important;
//  height: 205px;
//  border-radius: 7px ;
//  background: rgba(19,32,64,0.91);
//
//  z-index: -1;
//  clip-path: polygon(0% 0%, 100% 0%, 100% 95%, 52% 95%, 50% 100%, 48% 95%, 0 95%);
//}
//.popover .title{
//  /*height: 40px;*/
//  font-size: 18px;
//  font-weight: 600;
//  text-align: left;
//  color: #ffffff;
//  letter-spacing: 1px;
//  /*padding: 10px 20px 5px 20px;*/
//  padding: 20px;
//  display: flex;
//  justify-content: space-between;
//}
//.popover .title div:first-child, .warning-popover .title div:first-child{
//  width: 220px;
//  overflow: hidden;
//  text-overflow:ellipsis;
//  white-space: nowrap;
//}
//.popover .title div:last-child, .warning-popover .title div:last-child{
//  font-size: 14px;
//  width: 63px;
//  line-height: 25px;
//  opacity: 1;
//  background: #4fc164;
//  border-radius: 5px;
//  text-align: center;
//  /*cursor: pointer;*/
//}
//.popover .text{
//  font-size: 14px;
//  font-weight: 600;
//  text-align: left;
//  color: #ffffff;
//  line-height: 25px;
//  letter-spacing: 1px;
//  padding: 0 36px 0 18px;
//
//}
//.popover .text:last-child{
//  margin-bottom: 10px;
//}
//
//.popover .btn{
//  width: 323px;
//  height: 44px;
//  opacity: 1;
//  background: rgb(32, 61, 144);
//  border-radius: 6px;
//  margin: 10px auto;
//  font-size: 14px;
//  font-weight: 600;
//  text-align: center;
//  color: #ffffff;
//  line-height: 44px;
//  letter-spacing: 1px;
//  cursor: pointer;
//}
//
//.warning-popover{
//  z-index: 10;
//  filter:drop-shadow(-1px 1px 0 #d44b4b) drop-shadow(1px 1px 0 #d44b4b) drop-shadow(0 1px 0 #d44b4b) drop-shadow(1px 0 0 #d44b4b) drop-shadow(1px -1px 0 #d44b4b) drop-shadow(-1px -1px 0 #d44b4b) drop-shadow(0 -1px 0 #d44b4b) drop-shadow(-1px 0 0 #d44b4b);
//  position: relative;
//  width: 366px !important;
//  /*opacity: .91;*/
//}
//.warning-popover::after{
//  content: '';
//  position: absolute;
//  top: 0;
//  left: 0;
//  width: 366px !important;
//  height: 380px;
//  background: #772427;
//  /*border: 1px solid #d44b4b;*/
//  border-radius: 7px 7px 7px  7px;
//  /*box-shadow: 0px 7px 21px 0px rgba(62,154,249,0.42);*/
//  clip-path: polygon(0% 0%, 100% 0%, 100% 96%, 52% 96%, 50% 100%, 48% 96%, 0 96%);
//  z-index: -1;
//}
//.warning-popover .title{
//  font-size: 18px;
//  font-weight: 600;
//  text-align: left;
//  color: #ffffff;
//  line-height: 25px;
//  letter-spacing: 1px;
//  padding: 20px;
//  display: flex;
//  justify-content: space-between;
//}
//.warning-popover .title div:last-child{
//  font-size: 14px;
//  width: 63px;
//  height: 29px;
//  opacity: 1;
//  background: #d65050;
//  border-radius: 5px;
//  text-align: center;
//  /*cursor: pointer;*/
//}
//.warning-popover .text{
//  font-size: 14px;
//  font-weight: 600;
//  text-align: left;
//  color: #ffffff;
//  line-height: 25px;
//  letter-spacing: 1px;
//  padding: 0 36px 0 18px;
//  overflow: hidden;
//  text-overflow:ellipsis;
//  white-space: nowrap;
//}
//.warning-popover .btn{
//  width: 323px;
//  height: 44px;
//  opacity: 1;
//  background: #d81e06;
//  border-radius: 6px;
//  margin: 10px auto;
//  font-size: 14px;
//  font-weight: 600;
//  text-align: center;
//  color: #ffffff;
//  line-height: 44px;
//  letter-spacing: 1px;
//  cursor: pointer;
//}

.container {
  width: 366px !important;

  height: 230px;
  /* Border */
  border: 1px solid #5ba3ed;
  box-shadow: 0px 7px 21px 0px rgba(62,154,249,0.42);
  background: rgba(19,32,64,0.91);
  border-radius: 3px;
  /* Used to position the arrow */
  position: relative;

  .title, .text{
    color: #fff;
  }

  .title{
    display: flex;
    width: 366px;
    height: 50px;
    padding: 10px 16px;
    justify-content: space-between;
    letter-spacing: 1px;
  .left{
    font-size: 18px;
    font-weight: 600;
  //padding: 20px;
    line-height: 30px;
    width: 260px;
    height: 100%;
    text-align: left;

  }
  .right{
    font-size: 14px;
    width: 63px;
    height: 29px;
    line-height: 29px;
    border-radius: 3px;
    text-align: center;
  }
}
.text{
  font-size: 14px;
  padding: 0 16px 10px 16px;
  text-align: left;
  font-weight: 600;
  letter-spacing: 1px;
}
// 倒三角
   .container__arrow {
     /* Size */
     height: 16px;
     width: 16px;

     background-color: #FFF;
     position: absolute;
   }

.container__arrow--bc {
  /* Position at the top left corner */
  bottom: -16px;
  left: 50%;
  background: rgba(19,32,64,0.91);
  opacity: .91;
  /* Border */

  border-bottom: 1px solid #5ba3ed;
  border-right: 1px solid #5ba3ed;
  transform: translate(-50%, -50%) rotate(45deg);
}

}

.container-warn {
  width: 366px !important;

  height: 380px;
  /* Border */
  background: #772427;
  border: 1px solid #d44b4b;
  border-radius: 3px;
  box-shadow: 0px 7px 21px 0px rgba(62,154,249,0.42);
  /* Used to position the arrow */
  position: relative;

  .title, .text{
    color: #fff;
  }

  .title{
    display: flex;
    width: 366px;
    height: 50px;
    padding: 10px 16px;
    justify-content: space-between;
    letter-spacing: 1px;
    .left{
      font-size: 18px;
      font-weight: 600;
      //padding: 20px;
      line-height: 30px;
      width: 260px;
      height: 100%;
      text-align: left;

    }
    .right{
      font-size: 14px;
      width: 63px;
      height: 29px;
      line-height: 29px;
      border-radius: 5px;
      text-align: center;
      background: #d65050;
    }
  }
  .text{
    font-size: 14px;
    padding: 0 16px 10px 16px;
    text-align: left;
    font-weight: 600;
    letter-spacing: 1px;
  }
  .btn{
    width: 323px;
    height: 44px;
    opacity: 1;
    background: #d81e06;
    border-radius: 6px;
    margin: 10px auto;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
    line-height: 44px;
    letter-spacing: 1px;
    cursor: pointer;
  }
  // 倒三角
  .container__arrow {
    /* Size */
    height: 16px;
    width: 16px;

    background-color: #FFF;
    position: absolute;
  }

  .container__arrow--bc {
    /* Position at the top left corner */
    bottom: -16px;
    left: 50%;
    background: #772427;
    opacity: .91;
    /* Border */
    border-bottom: 1px solid #d44b4b;
    border-right: 1px solid #d44b4b;
    transform: translate(-50%, -50%) rotate(45deg);
  }
}
</style>
