<template>
  <div :class="[expand ? 'device-detail-content-expend': 'device-detail-content-no-expend']">
    <!--    <p>设备名称：{{ deviceInfo.deviceName || '无' }}</p>-->
    <div v-show="!expand" style="font-size: 16px;">设备告警次数: {{ deviceAlarmInfo.total }}</div>
<!--    <AlarmTable :device-alarm-info="deviceAlarmInfo.list"></AlarmTable>-->
    <HlTable
        v-show="expand"
        :table-data="deviceAlarmInfo.list"
        :column="alarmOptions.alarmColumn"
        :cell-style="alarmOptions.alarmCellStyle"
        :head-style="alarmOptions.alarmHeadStyle"
        color="rgba(23,38,76,0.89)"
        :height="`380px`"
        :loading="alarmLoad"
        @row-click="alarmClick"
    >
      <template #regionName="scope" >
        <div class="hidden-font" :title="scope.row.regionName">{{scope.row.regionName}}</div>
      </template>
    </HlTable>


    <div class="arrow">
      <img v-if="expand" src="../../../../../images/arrow-up.png" @click="arrowClick()" class="arrow-down"/>
      <img v-else src="../../../../../images/arrow-down.png" @click="arrowClick()" class="arrow-down"/>
    </div>
  </div>
</template>

<script>
import HlTable from "@/component/Tabel/HlTable";
import {alarmColumn,alarmCellStyle,alarmHeadStyle} from './index'
import {axiosServer} from "@/api/judge";
import bus from "@/utils/eventBus/bus";
export default {
  name: 'DeviceDetailAlarm',
  props: {
    msg: String
  },
  data() {
    return {
      alarmOptions:{
        alarmColumn,
        alarmCellStyle,
        alarmHeadStyle
      }
    }
  },
  components:{
    HlTable
  },
  computed:{
    expand(){
      return this.$store.getters.expand;
    },
    deviceAlarmInfo(){
      return this.$store.getters.deviceAlarmInfo;
    },
    alarmLoad(){
      return this.$store.getters.alarmLoad;
    }
  },
  methods: {
    arrowClick() {
      this.$store.commit('SET_EXPAND', !this.expand)
    },
    async alarmClick(e){
      console.log(e)
      if (e.isClear == 1){
        bus.$emit('dealDialog', {show: true, id: e.deviceId, state: 1, tableInfo: e});
      }else {
        bus.$emit('dealDialog', {show: true, id: e.deviceId, state: 2, tableInfo: e});
      }

      // let res = await axiosServer({
      //   url: '/device/alarm/queryById?deviceId=' + e.deviceId + "&appid=" + e.appid + "&alarmId=" + e.alarmId,
      //   type: 'GET',
      // })
      // if (res.code == 0){
      //   e.contacts = res.data.com.contacts;
      //   e.phone = res.data.com.phone;
      //   bus.$emit('dealDialog', {show: true, id: e.deviceId, state: 1, tableInfo: e});
      // }else {
      //   e.contacts = '暂无获取信息';
      //   e.phone = '暂无获取信息';
      //   bus.$emit('dealDialog', {show: true, id: e.deviceId, state: 1, tableInfo: e});
      // }
    }
  },
  activated() {
    console.log('activated')
  },
  deactivated() {
    console.log('deactivated')
  },
}
</script>

<style scoped>
.arrow{
  width: calc(100% - 44px);
  height: 20px;
  display: flex;
  position: absolute;
  bottom: 10px;
  left: 22px;
  justify-content: flex-end;
}
.arrow img{
  width: 18px;
  height: 10px;
  opacity: 1;
  display: block; margin-top: 5px;
}
.device-detail-content-no-expend{
  display: flex;
  align-items: center;
}
</style>
