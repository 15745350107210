<template>
  <el-row class="dev-status-row">
    <el-col :span="24">
      <div id="pieChart1" ref="myRef5" class="chart-box" />
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "SystemStaticChart",
  data(){
    return{
      countryColors: ['#5699ff',"#ef6a6e","#ff8256","#5fe569","#ffe456","#dc82fc","#7a61fc","#8195cb","#5ac8ca"],
      myChart: null,
      legendData: [{
        name: "全部",
        icon: "roundRect",
        itemStyle:{
          color: "#5ac8ca"
        }
      }, {
        name: '用电安全',
        icon: "roundRect",
        itemStyle:{
          color: "#5699ff"
        }
      },{
        name: '烟感',
        icon: "roundRect",
        itemStyle:{
          color: "#ef6a6e"
        }
      }, {
        name: '消防栓',
        icon: "roundRect",
        itemStyle:{
          color: "#ff8256"
        }
      }]
    }
  },
  methods:{
    init(){
      console.log("=====echart init=====")
      let that = this;
      let option = {
        legend: {
          data: this.legendData,
          textStyle: {
            color: '#fff',   // 设置图例文本颜色为红色
            fontSize: 12    // 设置图例文本字体大小为12px
          }
        },
        calculable: true,
        xAxis: {
          data: ['正常', '掉线', '故障', '告警', '休眠', '未知'],
          axisLabel: {
            //x轴文字的配置
            show: true,
            textStyle: {
              color: "#fff",
            },
          },
        },
        yAxis: {
          axisLabel: {
            //x轴文字的配置
            show: true,
            textStyle: {
              color: "#fff",
            },
          },
        },
        series: [
          {
            name: '全部',
            type: 'line',

          },
          {
            name: '用电安全',
            type: 'bar',
            data: [26, 24, 2, 0, 23, 20],
            emphasis: {
              focus: 'series'
            },
            itemStyle:{
              color: "#5699ff"
            }
          },
          {
            name: '烟感',
            type: 'bar',
            data: [23, 0, 0, 25, 27, 50],
            emphasis: {
              focus: 'series'
            },
            itemStyle:{
              color: "#ef6a6e"
            }
          },
          {
            name: '消防栓',
            type: 'bar',
            data: [26, 24, 2, 22, 23, 20],
            emphasis: {
              focus: 'series'
            },
            itemStyle:{
              color: "#ff8256"
            }
          },



        ]
      };
      this.myChart = this.$echarts.init(document.getElementById('pieChart1'));
      this.myChart.setOption(option);
      this.myChart.on('legendselectchanged', function(params) {
        console.log(params); // 输出被选中/取消选中的图例名称及对应的索引值
        if (params.name == '全部'){
          for (let i = 0; i < that.legendData.length; i++) {

              that.myChart.dispatchAction({
                type: 'legendSelect',
                name: that.legendData[i].name
              })

          }
        }else {
          for (let i = 0; i < that.legendData.length; i++) {
            if (params.name != that.legendData[i].name){
              console.log(that.legendData[i])
              that.myChart.dispatchAction({
                type: "legendUnSelect",
                name: that.legendData[i].name
              })
            }else {
              that.myChart.dispatchAction({
                type: 'legendSelect',
                name: that.legendData[i].name
              })
            }
          }
        }

      });
    },

  },
  watch:{

  },
  mounted(){
    // const myChart = this.$echarts.init(document.getElementById('pieChart5'));
    // myChart.setOption(this.option);
    this.init();
  },
  unmounted() {
    console.log('StatDeviceType === destroyed', this.myChart)
    this.$echarts.dispose(this.myChart)
    console.log(this.myChart)
  }
}
</script>

<style scoped>
.chart-box{
  width: 100%;
  height: 260px;
}
</style>
