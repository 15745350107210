<template>
  <el-row class="company-detail comm-bg" v-show="this.deviceCompanyShow">

    <div class="comm-title-container" style="z-index:2;" >
      <div class="hidden-btn" @click="hidden">
        <i class="el-icon-arrow-left"></i>
      </div>
     <div class="hidden-font" :title="comName">{{ comName}}</div>
    </div>

    <img src="../../../../../images/comm-shining.png" class="comm-shining-img">

    <div class="company-content">
      <div class="top">设备总数: {{comType.total}}</div>
      <div class="middle-1">
        <div>正常: {{ comType.normal  }}</div>
        <div>告警: {{ comType.warning }}</div>
        <div>故障: {{ comType.fault }}</div>
        <div>休眠: {{ comType.sleep }}</div>
        <div>掉线: {{ comType.offline  }}</div>
        <div>未知: {{ comType.unknown }}</div>
      </div>
      <div class="middle-2">
<!--        <div>设备列表：</div>-->
<!--        <div class="sort-btn">排序 <i class="el-icon-caret-bottom"></i></div>-->
<!--        <div class="sort-card">-->
<!--          <div>正常</div>-->
<!--          <div>正常</div>-->
<!--          <div>正常</div>-->
<!--          <div>正常</div>-->
<!--          <div>正常</div>-->
<!--          <div>正常</div>-->
<!--        </div>-->
      </div>
      <div class="bottom">
        <HlTable :table-data="comDevice" :options="comOptions" height="342" @row-click="companyClick">

        </HlTable>
<!--        <div class="bottom-card" v-for="item in comDevice" :key="item.deviceId">-->
<!--          <el-row>-->
<!--            <el-col :span="12" class="hidden-font" :title="item.deviceName">{{ item.deviceName }}</el-col>-->
<!--            <el-col :span="6" style="text-align: center">{{  item.ip }}</el-col>-->
<!--            <el-col :span="6" :style="{'text-align': 'right', color: translateColor(item.sbzt)}">-->
<!--              {{  translateText(item.sbzt) }}-->
<!--            </el-col>-->
<!--          </el-row>-->
<!--        </div>-->
      </div>
    </div>
  </el-row>
</template>

<script>
import bus from "@/utils/eventBus/bus";
import {getDeviceSbztColor, getDeviceStateColor, getStatusValue} from "@/utils/constants";
import HlTable from "@/component/Table/HlTable";
import {deviceColumn, deviceStyle} from "@/views/Layout/Main/MainLeft/components/index";
export default {
  name: "CompanyDetail",
  components:{
    HlTable
  },
  data(){
    return{
      comName: '',
      cardShow: false,
      comType: {
        total: 0,
        normal: 0,
        offline: 0,
        fault: 0,
        warning: 0,
        sleep: 0,
        unknown: 0
      },
      comOptions: {
        column: deviceColumn,
        style: deviceStyle
      }
    }
  },
  computed:{
    deviceCompanyShow(){
      return this.$store.getters.deviceCompanyShow;
    },
    comDevice(){
      this.comType = {
        total: 0,
        normal: 0,
        offline: 0,
        fault: 0,
        warning: 0,
        sleep: 0,
        unknown: 0
      }
      this.$store.getters.comDevice.map(item => {
        // item.sbzt == -1 && this.comType.unknown ++;
        // item.sbzt == 1 && this.comType.normal ++;
        // item.sbzt == 2 && this.comType.offline ++;
        // item.sbzt == 3 && this.comType.fault ++;
        // item.sbzt == 4 && this.comType.warning ++;
        // item.sbzt == 5 && this.comType.sleep ++;
        if (item.sbzt == 1){
          this.comType.normal ++
        }else if (item.sbzt == 2){
          this.comType.offline ++;
        }else if (item.sbzt == 3){
          this.comType.fault ++;
        }else if (item.sbzt == 4){
          this.comType.warning ++;
        }else if (item.sbzt == 5){
          this.comType.sleep ++;
        }else {
          this.comType.unknown ++;
        }
        this.comType.total ++;
      })
      return this.$store.getters.comDevice;
    },
  },
  methods:{
    hidden() {
      this.$store.commit('SET_DEVICE_COMPANY_SHOW', false);
    },
    companyClick(row){
      bus.$emit('mapMove', row)
    }
    // translateText(sbzt){
    //   return getStatusValue(sbzt);
    // },
    // translateColor(sbzt){
    //   return getDeviceSbztColor(sbzt);
    // },
  },
  mounted() {
    bus.$on('companyName', res => {
      this.comName = res.comName
    })
  },
  destroyed() {
    bus.$off('companyName');
  }
}
</script>

<style lang="less" scoped>
.comm-bg{
  width: 24%!important;
  height: 500px;
  .comm-title-container{
    display: flex;
    /*align-items: center;*/
    padding-left: 0;
    height: 56px;
    line-height: 56px;
    .hidden-btn{
      width: 18px;
      height: 56px;
      font-size: 16px;
      cursor: pointer;
      color: #fff;
      padding:  0 5px;
    }
    div{
      padding:  0 10px;
    }
  }
  .comm-shining-img{
    /* 鼠标穿透属性 让按钮可点击*/
    margin-top: -112px;
    margin-left: -247px;
    pointer-events: none;
  }
}

.company-detail {
  width: 496px;
  position: fixed;
  margin-left: 15px;
  left: 0;
  top: 85px;
  z-index: 5;
  .company-content{
    color: #fff;
    padding: 8px 6px;
    text-align: left;
    .top{
      margin-bottom: 10px;
      padding: 0 10px;
    }
    .middle-1{
      display: grid;
      padding: 0 10px;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 30px) ;
    }
    .middle-2{
      display: flex;
      justify-content: space-between;
      position: relative;

      .sort-btn{
        cursor: pointer;
      }

      .sort-card{
        color: #333333;
        position: absolute;
        top: 28px;
        right: 5px;
        height: auto;
        z-index: 15;
        display: none;
        border-radius: 2px;
        div{
          width: 55px;
          height: 25px;
          line-height: 25px;
          //padding: 30px;
          text-align: center;
          background: #fff;
        }
      }
      .sort-btn:hover + .sort-card{
        display: block;
      }
      .sort-card:hover{
        display: block;
      }
    }
    .bottom{
      height: 350px;
      overflow: auto;
      //
      //&::-webkit-scrollbar {
      //  width: 0px;
      //  height: 0px;
      //
      //}
      //.bottom-card{
      //  width: 100%;
      //  height: 40px;
      //  line-height: 40px;
      //}
    }
  }
}



</style>
