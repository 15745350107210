<template>
 <div>
   <div class="font-style">
     <div class="dev-title">设备总量</div>
     <div class="dev-size">{{ deviceNum.total + 2 }}</div>
     <div class="dev-num">台</div>

   </div>
   <div class="tab-card">
     <div class="tab-card-content">
       <div class="tap-content">
         <div class="tab-text">正常状态</div>

         <div  class="tap-text">{{deviceNum.normal }} 台</div>

       </div>
       <div class="tap-content">
         <div class="tab-text">掉线状态</div>
         <div class="tap-text">{{deviceNum.offline}} 台</div>
         <!--                    <div class="tap-num"></div>-->
       </div>
       <div class="tap-content">
         <div class="tab-text">告警状态</div>
         <div class="tap-text">{{deviceNum.warning}} 台</div>
         <!--                    <div class="tap-num">台</div>-->
       </div>
       <div class="tap-content">
         <div class="tab-text">故障状态</div>
         <div class="tap-text">{{deviceNum.fault}} 台</div>

       </div>
       <div class="tap-content">
         <div class="tab-text">休眠状态</div>
         <div class="tap-text">{{deviceNum.sleep}} 台</div>

       </div>

     </div>
   </div>
   <div class="line-style">
     <div
         class="line-style-on"
         :style="{width: `${formatPercentage((deviceNum.total - deviceNum.offline),deviceNum.total)}%` }"

     >
       <div v-show="formatPercentage((deviceNum.total - deviceNum.offline),deviceNum.total) > 40">
         <span class="line-on-text">在线率</span>
         <span>{{ formatPercentage((deviceNum.total - deviceNum.offline),deviceNum.total) }}%</span>
       </div>

     </div>
     <div
         class="line-style-off"
         :style="{width: `${formatPercentage((deviceNum.offline),deviceNum.total)}%`}"
     >


     </div>
   </div>
   <div class="online-total">
     <div class="online-percentage">
       <span></span>
       <span>在线率</span>
       <span>{{ formatPercentage((deviceNum.total - deviceNum.offline),deviceNum.total) }}%</span>
     </div>
     <div class="offline-percentage">
       <span></span>
       <span>掉线率</span>
       <span>{{ formatPercentage((deviceNum.offline),deviceNum.total) }}%</span>
     </div>
   </div>
 </div>
</template>

<script>
export default {
  name: "StatDevice1",
  props: {
    msg: String
  },
  data() {
    return {
      region:{
        id: 0,
        actualRegionLevel: 0,
      },
      comId:localStorage.getItem('comId'),
      createType:localStorage.getItem('createType'),
    }
  },
  computed:{
    deviceNum(){
      return this.$store.getters.deviceNum
    },
    offlineShow(){
      return this.$store.getters.commonSetting.offlineShow
    }
  },
  mounted() {
  },
  methods:{
    formatPercentage(num,total){
      // if ((num/total).toFixed(2) == 0.14) return 14;
      return ((num / total) * 100 ).toFixed(1);
    }
  }
}
</script>

<style scoped>
.font-style{
  height: 40px;
  color:white;
  background:rgba(255,255,255,0.1);
  width:100%;
  letter-spacing: 2px;
  border-radius: 5px;
  font-size: 14px;
  padding-left: 22px;
  display: flex;
}
.dev-title{
  font-size: 14px;
  line-height: 40px;
}
.dev-size{
  font-size: 24px;
  padding: 0 10px 0 24px;
  line-height: 40px;
  font-weight: 600;
  /*line-height: 40px;*/
}
.dev-num{
  font-size: 14px;
  line-height: 46px;
  font-weight: 600;
}


.tab-card{
  height: 190px;
  /*margin-left: 14px;*/
}
.tab-card-content{
  overflow-x: hidden;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-template-rows: repeat(2,76px);
  color: #fff;
  justify-content: space-around;
  align-content: space-evenly;
}
.tab-card .tap-content{
  width: 130px;
  height: 76px;
  /*line-height: 65px;*/
  text-align: right;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  font-weight: 900;
  font-size: 14px;
  position: relative;
  z-index: 3;
}
.tab-text{
  position: absolute;
  top: -5px;
  left: 78px;
  z-index: 4;
  font-size: 12px;
}
.tab-card .tap-content .tap-text{
  /*position: absolute;*/
  /*top: 37px;*/
  /*left: 70px;*/
  padding-top: 37px;
  padding-right: 8px;

}
.tap-num{
  position: absolute;
  top: 37px;
  left: 110px;
}
.tab-card .tap-content:nth-child(1){
  background-image: url("../../../../../images/normal.png");
  /*background: #000;*/
}
.tab-card .tap-content:nth-child(2){
  background-image: url("../../../../../images/offline.png");
  /*background: #000;*/
}
.tab-card .tap-content:nth-child(3){
  background-image: url("../../../../../images/warning.png");
  /*background: #000;*/
}
.tab-card .tap-content:nth-child(4){
  background-image: url("../../../../../images/fault.png");
  /*background: #000;*/
}
.tab-card .tap-content:nth-child(5){
  background-image: url("../../../../../images/sleep.png");
  /*background: #000;*/
}

.online-total{
  margin-top: 16px;
  font-size: 12px;
  display: flex;
  color: #ffffff;
}
.online-percentage, .offline-percentage{
  width: 160px;
  /*padding-right: 70px;*/
}
.online-percentage span:first-child,.offline-percentage span:first-child{
  margin-right: 7px;
  display: inline-block;
  width: 6px;
  height: 6px;
  opacity: 1;
  background: #4fc164;
  border-radius: 50%;
}
.offline-percentage span:first-child{
  background: #586a8c;
}
.online-percentage span:last-child, .offline-percentage span:last-child{
  margin-left: 16px;
}
.line-style{
  width: 100%;
  height: 43px;
  /*background: #000;*/
  display: flex;
}
.line-style-on{
  height: 100%;
  background: linear-gradient(90deg,#4fc164, #62c1a2);
  box-shadow: 5px 0px 12px 0px rgba(36,204,184,0.44);
  border-radius: 4px 0px 0px 4px;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  line-height: 43px;
}
.line-style-on span{
  margin-left: 10px;
  margin-right: 15px;
}

.line-style-off{
  height: 100%;
  background: #586a8c;
  border-radius: 0px 4px 4px 0px;
  box-shadow: 5px 0px 12px 0px #222222;
}
</style>
