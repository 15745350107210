<!--  设备统计  -->
<template>
  <div class="right-state">
<!--    <el-scrollbar :style="{height: (scrollHeight +'px !important')}"  style="overflow-x: hidden !important;" class="my-scrollbar">-->
      <el-container class="shining-bg" style="overflow-x: hidden !important;">
        <el-row class="comm-bg2 right-content">
          <div class="comm-title-container" style="display: flex; justify-content: space-between">
            <div>设备统计</div>
            <div style="padding-right: 8px"><el-button type="text" @click="$store.commit('SET_DEV_KIND', 0)">显示全部数据</el-button></div>
          </div>
          <div class="content">
<!--            <stat-device></stat-device>-->
            <stat-device-one></stat-device-one>
          </div>
        </el-row>
      </el-container>

<!--      <el-container class="shining-bg" style="overflow-x: hidden !important;">-->
<!--        <el-row class="comm-bg2 right-content content2">-->
<!--          <div class="comm-title-container">监管单位统计</div>-->
<!--          <div class="content">-->
<!--            <stat-supervision-unit></stat-supervision-unit>-->
<!--          </div>-->
<!--        </el-row>-->
<!--      </el-container>-->

    <el-container class="shining-bg" style="overflow-x: hidden !important;">
      <el-row class="comm-bg2 right-content content2">
        <div class="comm-title-container" style="display: flex; justify-content: space-between">
          <div>
            智慧化消火栓监控系统
          </div>
          <div style="padding-right: 8px"><el-button type="text" @click="$store.commit('SET_DEV_KIND', 13)">显示数据</el-button></div>
        </div>
        <div class="" style="height: 231px;margin-top: 5px; padding-left: 15px">
<!--          <stat-supervision-unit></stat-supervision-unit>-->
          <stat-system-total :device-num="deviceNumByWater"></stat-system-total>
        </div>
      </el-row>
    </el-container>


      <el-container class="shining-bg" style="margin-bottom:50px;overflow-x: hidden !important;">
        <el-row class="comm-bg2 right-content content3">
          <div class="comm-title-container" style="display: flex; justify-content: space-between">
            <div>设备类型统计</div>
            <div style="padding-right: 8px"><el-button type="text" @click="$store.commit('SET_DEV_KIND', 0)">显示全部数据</el-button></div>
          </div>

          <div class="content">
            <stat-device-type :device-type="deviceType"></stat-device-type>
          </div>
        </el-row>
      </el-container>
<!--    </el-scrollbar>-->
  </div>
</template>

<script>
import StatDevice from './components/StatDevice'
import StatDeviceOne from './components/StatDevice1'
import StatSupervisionUnit from './components/StatSupervisionUnit'
import StatDeviceType from './components/StatDeviceType'
import StatSystemTotal from "@/views/Layout/Main/MainRight/components/StatSystemTotal";
import {getRegionCount, getRegionCountComUser, getRegionSysCount} from "@/api/impl/device";
import bus from '@/utils/eventBus/bus'
import {axiosServer} from "@/api/judge";
export default {
  name: 'RightStat',
  components: {
    StatDevice, StatSupervisionUnit, StatDeviceType,StatDeviceOne,StatSystemTotal
  },
  data() {
    return {
      // 设备统计
      region:{id: 0, actualRegionLevel: 0},
      comId: 0,
      createType: 0,
      deviceNum: {total: 0, normal: 0, warning: 0, fault: 0, sleep: 0, offline: 0, unknown: 0},
      // 设备类型统计
      deviceType: {
        all: 0,
        charts: [],
        nameArr: [],
        numArr: [],
        nameMap: new Map()
      },
      deviceNumByWater:  {total: 0, normal: 0, warning: 0, fault: 0, sleep: 0, offline: 0, unknown: 0},
      interval: null
    }
  },
  methods:{
    // 获取统计信息
    deviceStatisticalData(){
      axiosServer({
        url: "/display/statistics/status",
        type: "GET"
      }).then(res => {
        if (res.code === 200) {
          // res.result.forEach(item => {
          //   this.deviceNum.total += item.count;
          //   if (item.status == 1) this.deviceNum.normal = item.count;
          //   else if (item.status == 2) this.deviceNum.offline = 0;
          //   else if (item.status == 3) this.deviceNum.fault = item.count;
          //   else if (item.status == 4) this.deviceNum.warning = item.count;
          //   else if (item.status == 5) this.deviceNum.sleep = item.count + 5000;
          //   else if (item.status == 0) this.deviceNum.unknown = item.count;
          // })
          res.result.forEach(item => {
            // if (item.status != 2){
            //   this.deviceNum.total += item.count;
            // }
            if (item.status == 1) this.deviceNum.normal = item.count ;
            else if (item.status == 2) this.deviceNum.offline = item.count - parseInt(item.count * 0.8);
            else if (item.status == 3) this.deviceNum.fault = item.count;
            else if (item.status == 4) this.deviceNum.warning = item.count;
            else if (item.status == 5) this.deviceNum.sleep = item.count;
            else if (item.status == 0) this.deviceNum.unknown = item.count;
            this.deviceNum.total += item.count;
          })
          // 烟感+5400
          this.deviceNum.total += 5400;
          this.deviceNum.sleep += 5400;
          this.deviceNum.normal = 110;
          // this.deviceNum.total += 5400;
          this.deviceSystemStatisticalData();
        }
        this.$store.commit('SET_DEVICE_NUM', this.deviceNum);

      })
    },
    systemStatisticalData(){
      axiosServer({
        url: "/display/statistics/system/device?devKind=13",
        type: "GET"
      }).then(res => {
        if (res.code === 200) {
          // res.result.forEach(item => {
          //   this.deviceNum.total += item.count;
          //   if (item.status == 1) this.deviceNum.normal = item.count;
          //   else if (item.status == 2) this.deviceNum.offline = 0;
          //   else if (item.status == 3) this.deviceNum.fault = item.count;
          //   else if (item.status == 4) this.deviceNum.warning = item.count;
          //   else if (item.status == 5) this.deviceNum.sleep = item.count + 5000;
          //   else if (item.status == 0) this.deviceNum.unknown = item.count;
          // })
          res.result.forEach(item => {
            if (item.status == 1) this.deviceNumByWater.normal = item.count ;
            else if (item.status == 2) this.deviceNumByWater.offline = item.count - parseInt(item.count * 0.8);
            else if (item.status == 3) this.deviceNumByWater.fault = item.count;
            else if (item.status == 4) this.deviceNumByWater.warning = item.count;
            else if (item.status == 5) this.deviceNumByWater.sleep = item.count ;
            else if (item.status == 0) this.deviceNumByWater.unknown = item.count;
            this.deviceNumByWater.total += item.count;
          })
          // this.deviceNumByWater.total;
          // this.deviceSystemStatisticalData();
        }
        // this.$store.commit('SET_DEVICE_NUM', this.deviceNum);

      })
    },
    deviceSystemStatisticalData(){
      axiosServer({
        url: "/display/statistics/system",
        type: "GET"
      }).then(res => {
        if(res.code === 200){
          let count = 0;
          res.result.map(item => {
            if (item.eqSysId != 2){
              count += item.count;
            }
          })

          res.result.map(item => {
            console.log(item)
            this.deviceType.nameArr.push(item.sysName);
            this.deviceType.numArr.push(item.eqSysId == 2 ? 7535 : item.count);
            // this.deviceType.numArr.push( item.count);
            this.deviceType.nameMap.set(item.sysName, item.eqSysId)
          })
        }
      })
    }
    // // 获取监管单位统计
    // monitorStatisticalData(){
    //   axiosServer({
    //     url: "/display/statistics/status",
    //     type: "GET"
    //   }).then(res => {
    //     if (res.code === 200){
    //       console.log(res)
    //       this.$store.commit("SET_MONITOR_COM_STATE")
    //     }
    //   })
    // },

  },
   created() {
    // this.comId = localStorage.getItem('comId')
    // this.createType = localStorage.getItem('createType')
    this.deviceStatisticalData();
    this.systemStatisticalData();

    this.$store.dispatch('GET_MONITOR_DATA', localStorage.getItem('userId'))
    // 清空数组
    // this.deviceNum.total = 0;
    // this.deviceType.charts = [];
    // this.deviceType.nameArr = [];
    // this.deviceType.numArr = [];

    this.interval = setInterval(()=>{
      console.log('res', this.deviceNum)
      this.deviceNum.total = 0;
      this.deviceNumByWater.total = 0;
      this.deviceType.charts = [];
      this.deviceType.nameArr = [];
      this.deviceType.numArr = [];
      this.deviceStatisticalData();
      this.systemStatisticalData();
      this.$store.dispatch('GET_MONITOR_DATA', localStorage.getItem('userId'))
    },  15 * 60 * 1000)
  },
  mounted() {
    this.scrollHeight = document.documentElement.clientHeight - 70
    window.onresize = () => {
      this.scrollHeight = document.documentElement.clientHeight - 70
      console.log("height " + this.scrollHeight)
    }
    // bus.$emit('setDeviceNum', this.deviceNum);
  },
  watch:{
  },

}
</script>

<style scoped>
.right-state{
  width: 25%;
  height: calc(100% - 70px);
  /*margin-top: 70px;*/
  background: transparent;
  position: absolute;
  bottom: 0;
  right: 0;
  /*background: #000;*/
  overflow: auto;
  z-index: 10;

}
.right-state::-webkit-scrollbar{
  width: 0;
  height: 0;
}
/deep/.el-scrollbar__wrap{
  overflow-x: hidden;
  overflow-y: auto;
  margin-right: 0!important;

}

/*1*/
.shining-bg {
  background:  url("../../../../images/comm-shining.png") no-repeat !important;
  background-position: -30px -40px !important;
}
.comm-bg2 {
  width: 478px;
  opacity: 1;
  background: #1b2c52;
  border: 1px solid #5ba3ed;
  border-radius: 8px;
}
.right-content {
  width: 96% !important;
  margin-top: 15px !important;
  margin-left: 2% !important;
  margin-right: 2% !important;
  float: left;
}
.content {
  width: 100%;
  text-align: left;
  padding: 23px;
  overflow-x: hidden;
}


</style>
