<template>
    <el-row class="dev-status-row">
      <el-col :span="24">
        <div id="pieChart5" ref="myRef5" class="chart-box" />
      </el-col>
    </el-row>
</template>

<script>

// import { defineComponent, getCurrentInstance, ref, onMounted } from 'vue';

export default({
  name: 'StatDeviceType',
  props: {
    msg: String,
    deviceType: Object
  },
  data(){
    return{
      countryColors: ['#5699ff',"#ef6a6e","#ff8256","#5fe569","#ffe456","#dc82fc","#7a61fc","#8195cb","#5ac8ca"],
      myChart: null
    }
  },
  methods:{
    init(val){
      let that = this;
      let option = {
        grid: [{
          top: 0,
          bottom: 0,
          left: 0,
          right: 60,
          containLabel: true
        }],
        xAxis: [
          {
            type: 'value',
            max: 100,
            show: false,
            splitLine: {
              show: false
            },
            itemStyle: {
              color:"#fff",
              fontSize: "12px"
            },
          }
        ],
        yAxis: [
          {
            type: 'category',
            // data:Object.keys(val.charts).map((key) => {
            //   return key;
            // }).sort((a,b) => b - a),
            data: val.nameArr,
            axisLabel: {
              interval: 0,
              rotate: 0,
              color: "#fff",
              fontSize: 12,
            },
            itemStyle:{
              shadowBlur: 10,

            },
            inverse: true,
            axisLine:{       //y轴线
              show:false
            },
            axisTick:{       //y轴刻度线
              show:false
            },
            splitLine: {
              show: false
            },
            max: 8
          }
        ],
        series: [
          {
            type: 'bar',
            stack: 'chart',
            z: 1,
            barWidth: 16,
            label: {
              position: 'right',
              show: true,
              formatter: (value) => {
                return value.data + " 台"
              },
              color:"#fff",
              fontSize: "12px"
            },
            itemStyle: {
              normal: {
                barBorderRadius:[8, 8, 8, 8],
                color: (param) => {
                  return this.countryColors[param.dataIndex] || '#ff6200';
                },
              },


          },
          // data: Object.keys(val.charts).map((key) => {
          //   return val.charts[key];
          // }).sort((a,b) => b - a)
            data: val.numArr,
        }]
      };
      this.myChart = this.$echarts.init(document.getElementById('pieChart5'));
      this.myChart.setOption(option);
      this.myChart.on("click", (params) => {
        console.log("chart:", params, that.deviceType.nameMap)
        that.$store.commit("SET_DEV_KIND", that.deviceType.nameMap.get(params.name))
      })
    },

  },
  watch:{
    deviceType:{
      handler (newVal){
        this.init(newVal)
      },
      deep: true
    }
  },
  mounted(){
    // const myChart = this.$echarts.init(document.getElementById('pieChart5'));
    // myChart.setOption(this.option);
  },
  destroyed() {
    console.log('StatDeviceType === destroyed', this.myChart)
    this.$echarts.dispose(this.myChart)
    console.log(this.myChart)
  }
})
</script>

<style scoped>

  .chart-desc{
    font-size: 14px;
    color: #ffffff;
    text-align: center;
    width: 100%;
    margin: 0;
    padding:0;
    letter-spacing: 1px;
  }

  .font-style{
    font-size:14px;
    color:white;
    background:rgba(255,255,255,0.1);
    width:100%;
    letter-spacing: 2px;
    padding: 5px;
    padding-left: 15px;
    border-radius: 5px;
  }

  .dev-size{
    margin-left:20px;
    margin-right:20px;
    font-size:22px;
    font-weight:bold;
  }

  .chart-box{
    width: 100%;
    height: 320px;
  }

</style>
