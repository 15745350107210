<template>
  <!--      :cell-style="options.warnStyle.warnCellStyle"-->
  <!--      :header-cell-style="options.warnStyle.warnHeadStyle"-->
  <vxe-table
      :border="options.style.border"
      auto-resize
      show-header-overflow
      :height="height"
      :data="tableData"
      :style="{'--color': options.style.hover || '#fff' }"
      :cell-style="options.style.cellStyle"
      :header-cell-style="options.style.headStyle"
      :loading="load"
      @cell-click="cellClickEvent"
      ref="hlTable"
  >
    <vxe-table-column
        v-for="(item, index) in options.column"
        :key="index"
        :type="item.type"
        :title="item.title"
        :field="item.field"
        :width="item.width"
        :cell-render="item.cellRender"
        :header-align="item.headerAlign"
        :align="item.align"
        :min-width="item.minWidth"
        :sortable="item.sortable"
        :filters="item.filters"
        :formatter="item.formatter"
    >
    </vxe-table-column>
  </vxe-table>
</template>

<script>
import {Filter} from 'vxe-table'
export default {
  name: "HlTable",
  props: {
    tableData:{
      type: Array,
      default: () => []
    },
    options:{
      type: Object,
      default: () => {}
    },
    load:{
      type: Boolean,
      default: false
    },
    scroll:{
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: '229'
    }
  },
  methods:{
    cellClickEvent(e){
      this.$emit('row-click', e.row);
    },
    judgeScrollHeight(e) {
      console.log("judgeScrollHeight")
      if (e.target.clientHeight + e.target.scrollTop === e.target.scrollHeight){
        console.log("y == judgeScrollHeight")
        this.$emit('is-scroll', true)
      }
    },
    initScrollListener(){
      // 开启监听
      this.$refs.hlTable.elemStore['main-body-wrapper'].addEventListener('scroll', this.judgeScrollHeight)
    },
    destroyScrollListener(){
      this.$refs.hlTable.elemStore['main-body-wrapper'].removeEventListener('scroll', this.judgeScrollHeight)
    }
  },
  mounted() {
    this.scroll && this.initScrollListener();
  },
  beforeDestroy() {
    this.scroll && this.destroyScrollListener();
  }
}
</script>

<style scoped>
/deep/.vxe-table--header-wrapper,/deep/.vxe-table--body-wrapper{
  background-color: transparent!important;
}

/deep/.vxe-table--body tr:hover{
  background-color: var(--color) !important;
}

/deep/.vxe-table--body-wrapper::-webkit-scrollbar{
  width: 7px;
  height: 7px;
}
/deep/.vxe-table--body-wrapper::-webkit-scrollbar:vertical{
  display: none;
}
/deep/.vxe-table--body-wrapper::-webkit-scrollbar-thumb{
  background-color: #5C6974 !important;
  border-radius: 20px !important;
}
/deep/.vxe-table--body-wrapper::-webkit-scrollbar-track{
  border-radius: 2px;
}

</style>
