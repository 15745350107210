<template>
  <div :class="[expand ? 'device-detail-content-expend': 'device-detail-content-no-expend']">
<!--    <p>设备名称：{{ deviceInfo.deviceName || '无' }}</p>-->
    <p class="hidden-font" :title="deviceInfo.deviceName.trim()">设备名称：{{ deviceInfo.deviceName.trim() || '无' }}</p>
    <p class="hidden-font" :title="deviceInfo.regionName">所属单位：{{ deviceInfo.regionName || '无' }}</p>

    <p v-if="expand" class="hidden-font" :title="deviceInfo.ip">联网地址：{{ deviceInfo.ip || '无' }}</p>
<!--    <p v-if="expand" class="hidden-font" :title="deviceInfo.ip"> SIM卡号：{{  deviceInfo.ip || '未知' }}</p>-->
<!--    <p v-if="expand" class="hidden-font" :title="deviceInfo.clientStateValue">客户机状态：{{ deviceInfo.clientStateValue || '未知' }}</p>-->
<!--    <p v-if="expand" class="hidden-font" :title="deviceInfo.statusValue" :style="{'color': renderBgColor(deviceInfo.sbzt)}">探测器状态：{{ deviceInfo.statusValue || '未知' }}</p>-->
<!--    <p v-if="expand" class="hidden-font" :title="deviceInfo.gateway">网关状态：{{ deviceInfo.gateway || '未知' }}</p>-->
    <p v-if="expand" class="hidden-font" :title="deviceInfo.createTime">安装时间：{{ deviceInfo.createTime || '未知' }}</p>
    <p v-if="expand" class="hidden-font" :title="deviceInfo.contacts">联系人：{{  deviceInfo.contacts || '无' }}</p>
    <p v-if="expand" class="hidden-font" :title="deviceInfo.phone ">联系电话：{{  deviceInfo.phone || '无' }}</p>
    <p v-if="expand" class="hidden-font" :title="deviceInfo.address">安装地址：{{ deviceInfo.address || '无' }}</p>

    <div class="arrow">
      <img v-if="expand" src="../../../../../images/arrow-up.png" @click="arrowClick()" class="arrow-down"/>
      <img v-else src="../../../../../images/arrow-down.png" @click="arrowClick()" class="arrow-down"/>
    </div>
  </div>
</template>

<script>
import {getDeviceStateColor} from "@/utils/constants";

export default {
  name: 'DeviceDetailInfo',
  props: {
    msg: String,
    // deviceInfo: Object
  },
  data() {
    return {
      // expand: true
    }
  },
  computed:{
    deviceInfo(){

      return this.$store.getters.deviceInfo;
    },
    realtimeData(){
      return this.$store.getters.realtimeData;
    },
    expand(){
      return this.$store.getters.expand;
    }
  },
  methods: {
    arrowClick() {
      this.$store.commit('SET_EXPAND', !this.expand)
    },
    // unicode2string(unicode){
    //   return eval("'" + unicode + "'");
    // }
    renderBgColor(sbzt){
      switch (sbzt){
        case 1:
          return '#4fc164';
        case 2:
          return '#a0a0a0';
        case 3:
          return '#EF7818';
        case 4:
          return '#FD3838';
        case 5:
          return '#4E95F7';
      }
    },
  },
  created(){
    // console.log('unicode2string',this.unicode2string('\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000'))
  },
  activated() {
    console.log('activated')
  },
  deactivated() {
    console.log('deactivated')
  },
}
</script>

<style scoped>

p {
  font-size: 14px;
  letter-spacing: 1px;
  margin: 0 0 19px 0;
}

.arrow{
  width: calc(100% - 44px);
  height: 20px;
  display: flex;
  position: absolute;
  bottom: 10px;
  left: 22px;
  justify-content: flex-end;
}
.arrow img{
  width: 18px;
  height: 10px;
  opacity: 1;
  display: block; margin-top: 5px;
}
</style>
