
import {vxeTable} from "@/component/vxe-table/index1";
import {getDeviceSbztColor, getStatusValue} from "@/utils/constants";
// alarm
export const alarmColumn = [
    {
        name: 'jcsj',
        prop: 'jcsj',
        label: '告警时间',
        headerAlign: 'left',
        align: 'center',
        width: 90,
    },
    {
        name: 'regionName',
        prop: 'regionName',
        label: '告警单位',
        headerAlign: 'center',
        align: 'center',
    },
    {
        name: 'sensorName',
        prop: 'sensorName',
        label: '告警传感',
        headerAlign: 'center',
        align: 'center'
    },
    {
        name: 'statusValue',
        prop: 'statusValue',
        label: '告警类型',
        headerAlign: 'right',
        align: 'right',
        width: 90
    },
]

export const alarmCellStyle = function () {
    return {
        background: "transparent",
        color: "#ffffff",
        border: "none",
        fontSize: "12px",
        padding: "5px 0 5px 0"
    };
}

export const alarmHeadStyle = function (){
    return {
        //rgba(130,36,36,0.91)
        background: "transparent",
        color: "#ffffff",
        fontSize: "16px",
        paddingTop: "16px",
        borderBottom: '1px solid #979797'
    };
}

// senor
export const senorColumn = [
    {
        name: 'sensorName',
        prop: 'sensorName',
        label: '传感器名称',
        headerAlign: 'left',
        align: 'left',
        width: 110,
    },
    {
        name: 'valRegion',
        prop: 'valRegion',
        label: '值范围',
        headerAlign: 'center',
        align: 'center',
        width: 140
    },
    {
        name: 'sbztValue',
        prop: 'sbztValue',
        label: '设备状态',
        headerAlign: 'center',
        align: 'center',
        width: 100
    },
    {
        name: 'realVal',
        prop: 'realVal',
        label: '设备值',
        headerAlign: 'right',
        align: 'right',
        width: 110
    },
]

export const senorCellStyle = function () {
    return {
        background: "transparent",
        color: "#ffffff",
        border: "none",
        fontSize: "14",
        padding: "5px 0 5px 0"
    };
}

export const senorHeadStyle = function (){
    return {
        //rgba(130,36,36,0.91)
        background: "transparent",
        color: "#ffffff",
        fontSize: "16px",
        paddingTop: "16px",
        border: 'none',
    };
}


// comDevice
vxeTable.renderer.add('deviceName', {
    renderHeader(h, renderOpts, params) {
        return[
            <div>设备列表</div>
        ]
    },
})
vxeTable.renderer.add('empty', {
    renderHeader(h, renderOpts, params) {
        return[
            <div></div>
        ]
    },
})
vxeTable.renderer.add('sbzt', {
    renderHeader(h, renderOpts, params) {
        return [
            <div>排序</div>
        ]
    },
    renderDefault(h, renderOpts, params) {
        let {row,column} = params
        console.log(column)
        return [
            <div style={`color: ${getDeviceSbztColor(row[column.property])}`}>{ getStatusValue(row[column.property]) }</div>
        ]
    }
})
export const deviceColumn = [
    {
        field: 'deviceName',
        title: '设备名称',
        headerAlign: 'left',
        align: 'left',
        cellRender: {name: 'deviceName'},
        minWidth: 200
    },
    {
        field: 'ip',
        title: '设备ip',
        headerAlign: 'center',
        align: 'center',
        cellRender: {name: 'empty'},
        width: 150
    },
    {
        field: 'sbzt',
        title: '设备状态',
        headerAlign: 'right',
        align: 'right',
        // sortable: true,
        filters: [{label: '未知', value: -1},{label: '正常', value: 1},{label: '掉线', value: 2} ,{label: '故障', value: 3},{label: '告警', value: 4},{label: '休眠', value: 5}],
        cellRender: {name: 'sbzt'},
        width: 100
    },
]

const deviceCellStyle = function () {
    return{
        // rgba(23,38,76,0.89)
        background: 'transparent',
        border: 'none',
        fontSize: '12px',
        color: '#ffffff',
        height: '20px',
        padding: '5px 0 5px 0',
        cursor: 'pointer',
    }
}

const deviceHeadStyle = function (){
    return {
        background: "transparent",
        color: "#ffffff",
        fontSize: "16px",
        // paddingTop: "16px",
        border: 'none'
    };
}

export const deviceStyle = {
    border: 'none',
    hover: '#5BA3ED4D',
    cellStyle: deviceCellStyle,
    headStyle: deviceHeadStyle
}
