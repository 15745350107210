<template>
  <div class="amap-page-container">
    <el-amap
        ref="map"
        map-style="amap://styles/62009be025f187dd3eafe327d2e55b8e"
        :center="center"
        :zoom="zoom"
        view-mode="3D"
        @init="initMap"
        @click="clickMap"
        class="amap-demo"

        mapStyle="amap://styles/grey"
    >
<!--      <el-amap-polygon :path="polygon.path" :visible="polygon.visible" :editable="polygon.edit" :draggable="polygon.draggable" @click="clickPolygon"></el-amap-polygon>-->
      <el-amap-info-window
          :position="infoPosition"
          :visible.sync="infoVisible"
          :auto-move="false"
          :is-custom="true"
          :offset="[0, -25]"
      >
        <!--  点击  -->
        <div class="container" v-show="overlayType === 0">
          <div class="title ">
            <div class="left hidden-font" :title="deviceInfo.deviceName">{{ deviceInfo.deviceName || '无' }}</div>
            <div class="right" :style="{background: renderBgColor(deviceInfo.sbzt)}">{{ renderText(deviceInfo.sbzt) }}</div>
<!--                    <div :style="{backgroundColor: renderBgColor(item.sbzt)}">{{ renderText(item.sbzt) }}</div>-->
          </div>
          <div class="text hidden-font" :title="deviceInfo.regionName">所属单位：{{ deviceInfo.regionName || '无' }}</div>

          <div class="text hidden-font" :title="deviceInfo.contacts">联系人：{{  deviceInfo.contacts || '无' }}</div>
          <div class="text hidden-font" :title="deviceInfo.phone">联系电话：{{  deviceInfo.phone || '无' }}</div>
          <div class="text hidden-font text-last" :title="deviceInfo.alarmSize">告警总次数：{{   deviceInfo.alarmSize || '0' }}</div>
          <div class="text " :title="deviceInfo.detailAddress">详细位置：{{ deviceInfo.detailAddress || '无' }}</div>
<!--          <div class="text" @click.stop="openVideo" style="cursor: pointer">查看监控</div>-->
          <div class="container__arrow container__arrow--bc" ></div>
        </div>
        <!--  告警  -->
        <div class="container-warn" v-show="overlayType === 1">
          <div class="title ">
            <div class="left hidden-font" :title="deviceAlarmInfo.deviceName || '无'">{{ deviceAlarmInfo.deviceName || '无' }}</div>
            <div class="right" >告警</div>
            <!--        <div :style="{backgroundColor: renderBgColor(item.sbzt)}">{{ renderText(item.sbzt) }}</div>-->
          </div>
          <div class="text hidden-font" :title="deviceAlarmInfo.regionName">所属单位：{{ deviceAlarmInfo.regionName || '无' }}</div>
          <div class="text hidden-font" :title="deviceAlarmInfo.detailAddress">详细位置：{{ deviceAlarmInfo.address || '无' }}</div>
          <div class="text hidden-font" :title="deviceAlarmInfo.contacts">联系人：{{ deviceAlarmInfo.contacts || '无' }}</div>
          <div class="text hidden-font" :title="deviceAlarmInfo.phone">联系电话：{{ deviceAlarmInfo.phone || '无' }}</div>
          <div class="text hidden-font" :title="deviceAlarmInfo.jcsj">告警时间：{{ deviceAlarmInfo.jcsj || '无' }}</div>
          <div class="text hidden-font" :title="deviceAlarmInfo.statusValue">告警类型：{{ deviceAlarmInfo.statusValue || '无' }}</div>
          <div class="text hidden-font" :title="deviceAlarmInfo.sensorName">告警传感：{{ parseSensorName(deviceAlarmInfo.sensorName) || '无' }}</div>
          <div class="text hidden-font"
               :title="deviceAlarmInfo.jcz + parseSensorUnit(deviceAlarmInfo.sensorName)"
          >
            告警数据：{{ deviceAlarmInfo.jcz + parseSensorUnit(deviceAlarmInfo.sensorName) || '无' }}
          </div>

<!--          <div class="text" @click.stop="openVideo" style="cursor: pointer">查看告警视频</div>-->
          <div class="btn" @click="dealWarning">处理告警</div>
          <div class="container__arrow container__arrow--bc" ></div>
        </div>

        <!--  搜索  -->
        <div class="container" v-show="overlayType === 2">
          <div class="title ">
            <div class="left hidden-font" :title="searchDeviceInfo.deviceName || '无'">{{ searchDeviceInfo.deviceName || '无' }}</div>
            <div class="right" :style="{background: renderBgColor(searchDeviceInfo.sbzt)}">{{ renderText(searchDeviceInfo.sbzt) }}</div>
            <!--        <div :style="{backgroundColor: renderBgColor(item.sbzt)}">{{ renderText(item.sbzt) }}</div>-->
          </div>
          <div class="text hidden-font" :title="searchDeviceInfo.regionName">所属单位：{{ searchDeviceInfo.regionName || '无' }}</div>

          <div class="text hidden-font" :title="searchDeviceInfo.contacts">联系人：{{  searchDeviceInfo.contacts || '无' }}</div>
          <div class="text hidden-font" :title="searchDeviceInfo.phone">联系电话：{{  searchDeviceInfo.phone || '无' }}</div>
          <div class="text hidden-font" :title="searchDeviceInfo.alarmSize">告警总次数：{{   searchDeviceInfo.alarmSize || '0' }}</div>
          <div class="text " :title="searchDeviceInfo.detailAddress">详细位置：{{ searchDeviceInfo.detailAddress || '无' }}</div>
<!--          <div class="text" @click.stop="openVideo" style="cursor: pointer">查看告警视频</div>-->
          <div class="container__arrow container__arrow--bc" ></div>
        </div>
      </el-amap-info-window>
      <el-amap-loca @init="initLoca">
<!--        <el-amap-loca-scatter :visible="locaVisible" :source-data="location.normal" :layer-style="layerStyle"></el-amap-loca-scatter>-->
        <el-amap-loca-icon :visible="locaVisible" :source-data="MapData" @click="clickIcon" :layer-style="layerStyle"></el-amap-loca-icon>
      </el-amap-loca>
    </el-amap>
  </div>
</template>

<script>
import dg_json from '@/json/dg.json'
import {getDeviceAlarmInfoLatest, getDeviceMap} from "@/api/impl/device";
import bus from "@/utils/eventBus/bus";
import {parseOneLine, parseSensor, parseSensorAlarm, parseUnit} from "@/utils/device/DetectorValueParser";
import axios from "@/api/axios";
import {axiosServer} from "@/api/judge";
export default {
  name: "GaoDeMapShow",
  props: {
    mapDeviceStatus: {
      type: Number,
      default: 0
    }
  },
  data(){
    return {
      mapInit: false,
      // 地图api
      zoom: 10,
      // center: [113.993932, 22.948193],

      center: [114.146431,22.780529],
      // mask: []
      polygon:{
        draggable: false,
        visible: true,
        edit: false,
        path: [],
      },
      // 地图数据
      location: {
        normal: {
          type: "FeatureCollection",
          features: []
        },
        fault: [],
        alarm: [],
        drop: [],
        unknown: [],
        sleep: []
      },
      MapData: {
        type: "FeatureCollection",
        features: []
      },
      mapData: [],
      // loca
      layerStyle: {
        unit: 'px',
        icon: (index, feature) => {
          let sbzt = feature.properties.sbzt;
          if (sbzt == 1){
            return require("../../../../../images/green.png");
          }else if (sbzt == 2){
            return require("../../../../../images/grey.png");
          }else if (sbzt == 3){
            return require("../../../../../images/yellow.png");
          }else if (sbzt == 4){
            return require("../../../../../images/red.png");
          }else if (sbzt == 5){
            return require("../../../../../images/blue.png");
          }else {
            return require("../../../../../images/grey.png");
          }
        },
        iconSize: [30,30],
        rotation: 0,
      },
      sourceUrl: 'https://a.amap.com/Loca/static/loca-v2/demos/mock_data/sz_road_F.json',
      locaVisible: true,
      // infowindow
      infoPosition: [121.5273285, 31.21515044],
      infoVisible: false,
      infoSbzt: 0,
      alarmBody:{
        page: 1,
        pageSize: 20,
        alarmType: 1,
        deviceId: null
      },
      appid: null,
      deviceAlarmInfo: {

      },
      searchDeviceInfo: {}
    };
  },
  methods: {
    // 地图api
    clickMap(e){
      console.log('click map :', e );
      this.infoVisible = false;
    },
    initMap(e){
      this.initMapData();
      this.mapInit = true;
    },
    clickPolygon(e){
      return;
    },
    // loca
    initLoca(loca){
      loca.animate.start();
    },
    clickIcon(e){
      console.log('click icon :', e );
      if (e){
        this.$nextTick(() => {
          this.infoVisible = true;
          this.infoPosition = [e.coordinates[0],e.coordinates[1]];
          this.infoSbzt = e.properties.sbzt;
          e.properties.deviceId && this.$store.commit('SET_DEVICE_ID', e.properties.deviceId)
          this.$store.commit('SET_OVERLAY_CARD_TYPE', 0);
          this.$store.commit('SET_DEVICE_DETAILS_SHOW', true)
          this.$store.commit('SET_EXPAND', false)
          this.appid = e.properties.appid;
          axiosServer({
            url: "/display/device/queryById?deviceId=" + e.properties.deviceId + "&appid=" + e.properties.appid,
            type: "GET"
          }).then(res => {
            console.log(res)
            if (res.code === 200){
              this.$store.commit("SET_DEVICE_INFO", res.result);
            }
          })
        })
      }else {
        this.$store.commit('SET_EXPAND', false)
      }

    },
    // 初始化数据
    initMapData(){
      axiosServer(
          {
            url: "/display/device/map/locations",
            type: "GET"
          }
      ).then(res => {
          this.mapData = Object.freeze(res.result);
          this.mapData.forEach((item) => {
            this.MapData.features.push({
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [item.lng, item.lat]
              },
              properties: {
                deviceId: item.deviceId,
                sbzt: item.sbzt,
                appid: item.appid,
                devKind: item.devKind
              }
            })
          })
      })
    },
    changeMapData(){
      this.MapData = {
        type: "FeatureCollection",
        features: []
      }
      if (this.sbzt){
        this.mapData.map((item) => {
          if (this.sbzt == item.sbzt){
            this.MapData.features.push({
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [item.lng, item.lat]
              },
              properties: {
                deviceId: item.deviceId,
                sbzt: item.sbzt,
                appid: item.appid
              }
            })
          }
        });
      }else {
        this.mapData.map((item) => {
            this.MapData.features.push({
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [item.lng, item.lat]
              },
              properties: {
                deviceId: item.deviceId,
                sbzt: item.sbzt,
                appid: item.appid
              }
            })
        });
      }

    },
    changeMapDataByDevKind(){
      console.log("changeMapDataByDevKind")
      this.MapData = {
        type: "FeatureCollection",
        features: []
      }
      if (this.sbzt){
       if (this.devKind){
         this.mapData.map((item) => {
           if (this.sbzt == item.sbzt && this.devKind == item.devKind){
             this.MapData.features.push({
               type: "Feature",
               geometry: {
                 type: "Point",
                 coordinates: [item.lng, item.lat]
               },
               properties: {
                 deviceId: item.deviceId,
                 sbzt: item.sbzt,
                 appid: item.appid
               }
             })
           }
         });
       }else {
         this.mapData.map((item) => {
           if (this.sbzt == item.sbzt){
             this.MapData.features.push({
               type: "Feature",
               geometry: {
                 type: "Point",
                 coordinates: [item.lng, item.lat]
               },
               properties: {
                 deviceId: item.deviceId,
                 sbzt: item.sbzt,
                 appid: item.appid
               }
             })
           }
         });
       }

      }else {
        console.log("sbzt = 0")
        if (this.devKind){
          console.log("devKind > 0")
          this.mapData.map((item) => {
            if (this.devKind == item.devKind){
              this.MapData.features.push({
                type: "Feature",
                geometry: {
                  type: "Point",
                  coordinates: [item.lng, item.lat]
                },
                properties: {
                  deviceId: item.deviceId,
                  sbzt: item.sbzt,
                  appid: item.appid
                }
              })
            }
          });
        }else {
          console.log("devKind = 0")
          this.mapData.map((item) => {
            this.MapData.features.push({
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [item.lng, item.lat]
              },
              properties: {
                deviceId: item.deviceId,
                sbzt: item.sbzt,
                appid: item.appid
              }
            })
          });
        }

      }
    },
    // 显示overlay颜色
    renderBgColor(sbzt){
      switch (sbzt){
        case 1:
          return '#4fc164';
        case 2:
          return '#a0a0a0';
        case 3:
          return '#EF7818';
        case 4:
          return '#FD3838';
        case 5:
          return '#4E95F7';
      }
    },
    // 显示overlay文字
    renderText(sbzt){
      switch (sbzt){
        case 1:
          return '正常';
        case 2:
          return '掉线';
        case 3:
          return '故障';
        case 4:
          return '告警';
        case 5:
          return '休眠';
      }
    },
    parseSensorName(value){
      console.log("parseSensorName value:", value)
      return parseSensorAlarm(value);
      // if(value.length < 2 ){
      //   return  "无";
      // }else if (value.length == 2){
      //   return parseSensor(value);
      // }else {
      //   value = value.slice(0,2);
      //   return parseSensor(value);
      // }

    },
    parseSensorUnit(value){
      return parseUnit(value);
    },
    openVideo(){
      bus.$emit('videoDialog', {show: true, id: this.deviceId, state: 0});
    },
    // 打开处理警告弹框
    dealWarning(event){
      // 阻止点击事件穿透
      event.stopPropagation();
      // state 0处理告警 1实时告警
      // bus.$emit('dealDialog', {show: true, id: this.deviceId, state: 0});
      bus.$emit('dealDialog', {show: true, id: 0, state: 0, alarmInfo: this.deviceAlarmInfo});
    },
    // 定时获取实时数据
    startARequestRealtimeInterval(id) {
      this.interval = setInterval(() => {
        this.$store.dispatch('GET_SENSOR_RT_DATA_BY_ID', {
          appid: this.appid,
          deviceId: id
        })
      },  30 * 1000);
    },
    // 获取设备告警信息
    getAlarmById(id){
      this.alarmBody.deviceId = id;
      this.$store.dispatch('GET_ALARM_DATA', this.alarmBody)
    },
  },
  computed:{
    deviceId(){
      return this.$store.getters.deviceId;
    },
    deviceInfo(){
      return this.$store.getters.deviceInfo;
    },
    overlayType(){
      return this.$store.getters.overlayType;
    },
    deviceAlarm(){
      return this.$store.getters.deviceAlarm;
    },
    sbzt(){
      return this.$store.getters.sbzt
    },
    devKind(){
      return this.$store.getters.devKind
    },
  },
  created() {
    console.log("GaoDeMapShow")
    // 初始化数据
    // this.initMapData();
    // polygon 多边形
    this.polygon.path = Object.freeze(dg_json.features[0].geometry.coordinates[0]);
  },
  mounted() {
    // 监听eventBus
    bus.$on('mapMove', device => {
      console.log(device)
      this.$store.commit('SET_OVERLAY_CARD_TYPE', 2);
      this.center = [device.lng, device.lat];
      this.infoSbzt = device.sbzt;
      this.infoVisible = true;
      this.infoPosition  = [device.lng, device.lat];
      this.searchDeviceInfo = device;
      axiosServer({
        url: "/display/device/alarmSize?deviceId=" + device.deviceId + "&appid=" + device.appid,
        type: "GET"
      }).then(res => {
        if (res.code ==200){
          this.searchDeviceInfo.alarmSize = res.result
        }
      })
      this.$store.commit("SET_DEVICE_INFO", device);
      this.$store.dispatch('GET_SENSOR_RT_DATA_BY_ID', {
        deviceId: device.deviceId,
        appid: device.appid
      });
      this.getAlarmById(device.deviceId);
    });


    bus.$on('requestAlarm', async obj => {
      console.log('requestAlarm', obj)
      // 需要地图初始化成功才可以调用之后的方法
      if (this.mapInit){
        await axiosServer({
          url: "/display/alarm/queryById?deviceId=" + obj.deviceId + "&appid=" + obj.appid + "&alarmId=" + obj.alarmId + "&state=" + obj.state,
          type: "GET"
        }).then(res => {
          console.log("===alarm===")
          console.log(res)
          if (res.code == 200){
            this.$store.commit('SET_OVERLAY_CARD_TYPE', 1);
            bus.$emit('alarmPlay');

            this.deviceAlarmInfo = res.result;
            this.infoVisible = true;
            this.infoPosition = [res.result.lng, res.result.lat];
            this.center = [res.result.lng, res.result.lat];

          }
        })
        await this.$store.dispatch('GET_WARNING_DATA', {
          body:{
            closed: 1,
            page: 1,
            alarmType: 1,
            pageSize: 50,
          },
          type: 1
        })
      }
    });

    bus.$on('closeOverlay', obj => {
      if (obj.alarmId == this.deviceAlarmInfo.alarmId && obj.appid == this.deviceAlarmInfo.appid){
        this.infoVisible = false;
      }else {
        this.infoVisible = true;
      }
    })
  },
  watch: {
    // 监听vuex中设备id的值 只要设备id发生变化 执行获取设备信息的方法
    "$store.getters.deviceId": {
      handler(newVal) {
        // 清除上一个定时器
        clearInterval(this.interval)
        // 根据设备id请求数据
        // this.$store.dispatch('GET_DEVICE_INFO_BY_ID', newVal, this.unitId);
        this.$store.dispatch('GET_SENSOR_RT_DATA_BY_ID', {
          deviceId: newVal,
          appid: this.appid
        });
        // this.$store.dispatch('GET_DEVICE_SENSOR_BY_ID', newVal);
        this.$store.dispatch('GET_DEVICE_SENSOR_HISTORY_BY_ID', newVal)
        this.startARequestRealtimeInterval(newVal);
        this.getAlarmById(newVal)
      }
    },
    "$store.getters.sbzt": {
      handler(newVal, oldVal){
        if (newVal != oldVal){
          this.changeMapDataByDevKind();
        }
      }
    },
    "$store.getters.devKind": {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          console.log("chang devKind", newVal, oldVal)
         this.changeMapDataByDevKind();
        }
      }
    }
  },
  destroyed(){
    console.log("map 卸载监听")
    bus.$off(['searchMove','requestAlarm','closeOverlay']);
  }
}
</script>

<style lang="less" scoped>
.amap-page-container {
  width: 100% !important;
  height: 100%;
}

.container {
  width: 366px !important;

  height: 230px;
  /* Border */
  border: 1px solid #5ba3ed;
  box-shadow: 0px 7px 21px 0px rgba(62,154,249,0.42);
  background: rgba(19,32,64,0.91);
  border-radius: 3px;
  /* Used to position the arrow */
  position: relative;

  .title, .text{
    color: #fff;
  }

  .title{
    display: flex;
    width: 366px;
    height: 50px;
    padding: 10px 16px;
    justify-content: space-between;
    letter-spacing: 1px;
    .left{
      font-size: 18px;
      font-weight: 600;
      //padding: 20px;
      line-height: 30px;
      width: 260px;
      height: 100%;
      text-align: left;

    }
    .right{
      font-size: 14px;
      width: 63px;
      height: 29px;
      line-height: 29px;
      border-radius: 3px;
      text-align: center;
    }
  }
  .text{
    font-size: 14px;
    padding: 0 16px 10px 16px;
    text-align: left;
    font-weight: 600;
    letter-spacing: 1px;
  }
  // 倒三角
  .container__arrow {
    /* Size */
    height: 16px;
    width: 16px;

    background-color: #FFF;
    position: absolute;
  }

  .container__arrow--bc {
    /* Position at the top left corner */
    bottom: -16px;
    left: 50%;
    background: rgba(19,32,64,0.91);
    opacity: .91;
    /* Border */

    border-bottom: 1px solid #5ba3ed;
    border-right: 1px solid #5ba3ed;
    transform: translate(-50%, -50%) rotate(45deg);
  }

}

.container-warn {
  width: 366px !important;

  height: 380px;
  /* Border */
  background: #772427;
  border: 1px solid #d44b4b;
  border-radius: 3px;
  box-shadow: 0px 7px 21px 0px rgba(62,154,249,0.42);
  /* Used to position the arrow */
  position: relative;

  .title, .text{
    color: #fff;
  }

  .title{
    display: flex;
    width: 366px;
    height: 50px;
    padding: 10px 16px;
    justify-content: space-between;
    letter-spacing: 1px;
    .left{
      font-size: 18px;
      font-weight: 600;
      //padding: 20px;
      line-height: 30px;
      width: 260px;
      height: 100%;
      text-align: left;

    }
    .right{
      font-size: 14px;
      width: 63px;
      height: 29px;
      line-height: 29px;
      border-radius: 5px;
      text-align: center;
      background: #d65050;
    }
  }
  .text{
    font-size: 14px;
    padding: 0 16px 10px 16px;
    text-align: left;
    font-weight: 600;
    letter-spacing: 1px;
  }
  .btn{
    width: 323px;
    height: 44px;
    opacity: 1;
    background: #d81e06;
    border-radius: 6px;
    margin: 40px auto;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
    line-height: 44px;
    letter-spacing: 1px;
    cursor: pointer;
  }
  // 倒三角
  .container__arrow {
    /* Size */
    height: 16px;
    width: 16px;

    background-color: #FFF;
    position: absolute;
  }

  .container__arrow--bc {
    /* Position at the top left corner */
    bottom: -16px;
    left: 50%;
    background: #772427;
    opacity: .91;
    /* Border */
    border-bottom: 1px solid #d44b4b;
    border-right: 1px solid #d44b4b;
    transform: translate(-50%, -50%) rotate(45deg);
  }
}
</style>
