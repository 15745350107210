const baseUrl = {
  BASE_API: '',
  BASE_API_old: '',
}

switch (process.env.NODE_ENV) {
    // case 'development':
    //   baseUrl.BASE_API = 'http://192.168.1.105:8100/iot-dd'
    //   baseUrl.WS_URL = 'ws://192.168.1.105:9090/WebSocket/User'
    //   break
  case 'development':
    baseUrl.BASE_API = 'http://127.0.0.1:12000/supplier-api'
    // baseUrl.WS_URL = 'ws://192.168.0.66:8055/WebSocket/alarm'
    baseUrl.WS_URL= 'ws://127.0.0.1:12000/supplier-api/websocket'
    break
  case 'production':
    baseUrl.BASE_API = 'https://fire.fenggangf119.cn/supplier-api'
    baseUrl.WS_URL= 'wss://fire.fenggangf119.cn/supplier-api/websocket'
    break
}

export default baseUrl
