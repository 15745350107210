<template>
  <el-dialog
      :visible.sync="show"
      width="1000px"

      custom-class="setting"
      :close-on-click-modal="false"
      :before-close="close"
  >
    <JessibucaDemo ref="video" style="margin-top: 50px" :video-url="videoUrl" />
  </el-dialog>
</template>

<script>
import {Message} from 'element-ui'
import {axiosServer} from "@/api/judge";
import baseUrl from "@/api/baseUrl";
import {closeAlarm} from "@/api/impl/alarm";
import bus from "@/utils/eventBus/bus";
import JessibucaDemo from "@/component/JessibucaDemo";
export default {
  name: "VideoDialog",
  components: {
    JessibucaDemo
  },
  props:{
    show:{
      type: Boolean,
      default: false
    },
    state: {
      type: Number,
      default: 0
    },
    deviceId: {
      type:Number,
      default: 0
    }

  },
  data() {
    return {
      action: '',
      labelWidth: '150px',
      // 实时警告
      reqsBody: {
       body: {
         closed: 1,
         page: 1,
         alarmType: 1,
         pageSize: 100,
       },
        type: 1
      },
      isVisibleVideo: false,
      videoUrl: ""
    }
  },
  computed:{
  },
  // 上传图片成功回调方法
  methods: {
    // 关闭按钮
    close() {
      this.$emit('changeShow', 9)
      this.$refs.video.destroy();
    },
    // 取消按钮
    cancel() {
      // 弹框隐藏
      this.$emit('changeShow', 9)
      this.$refs.video.destroy();
    },
    // 确定按钮
    confirm() {
      this.$emit('changeShow', 9)
    },

  },
  mounted(){

  },
  watch:{
    show: {
      handler(newValue, oldValue){
        if (newValue){
          this.$nextTick(()=> {
            this.$refs.video.play();
          })
        }
      }
    }
  }
}
</script>

<style scoped>
.el-dialog__wrapper{
  width: 100vw;
  height: 100vh;

}
/deep/.el-dialog{
  height: auto;
  background: #17264c;
  height: 600px;
  margin:0 !important;
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
}
/deep/ .el-pager li{
  background: #17264c;
}
/* 顶部文字区域 */
.setting-text {
  padding-top: 40px;
  text-align: center;
  font-size: 18px;
  color: #fff;
}

/* 表单区域 */

.deal-form {
  margin: 20px 30px 0 10px;
}

.el-form {
  width: 100%;
}

.el-form-item {
  margin-bottom: 10px;
}

/deep/ .el-form-item__label {
  /*height: 30px;*/
  line-height: 30px;
  color: #fff;
  /*margin-right: 20px;*/
  font-size: 18px;
  padding: 0 20px 0 0;
}

.text {
  height: 30px;
  line-height: 30px;
  color: #fff;
  font-size: 18px;
}

/deep/ .el-input__inner {
  /*width: 772px;*/
  height: 48px;
  background: #17264c !important;
  border: 1px solid rgba(91, 163, 237, 0.50) !important;
  border-radius: 8px;
}

/*/deep/ .el-select .el-input__inner {*/

/*  !*color: #EAEAEA4D!important;*!*/
/*  background: #17264c !important;*/
/*  border: 1px solid rgba(91, 163, 237, 0.50) !important;*/
/*  border-radius: 8px;*/
/*}*/

/*/deep/ .el-input__prefix, /deep/ .el-input__suffix {*/
/*  top: 0px;*/
/*}*/

.avatar-uploader {
  width: 188px;
  height: 188px;
  background: #17264c;
  border: 1px solid rgba(91, 163, 237, 0.50);
  border-radius: 8px;
  box-sizing: border-box;
}

.avatar-uploader-icon {
  font-size: 18px;
  color: #5BA3ED;
  width: 188px;
  height: 188px;
  line-height: 188px;
  text-align: center;
}

.upload /deep/ .el-form-item__content {
  display: flex;
}

.avatar {
  width: 186px;
  height: 186px;
  object-fit: cover;
  object-position: top left;
  border-radius: 8px;
}

.text-warning {
  height: 188px;
  display: flex;
  justify-content: flex-end;
  margin-left: 20px;
  flex-direction: column;
}

.text-warning div {
  height: 25px;
  line-height: 25px;
  font-size: 18px;
  color: #EAEAEA4D;
  font-weight: 400;
  letter-spacing: 1px;
}

/*按钮*/
.deal-button {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  margin-bottom: 61px;
  padding-top: 170px;
}

.el-button {
  width: 411px;
  height: 49px;
  opacity: 1;
  color: #fff;
  background: #0364cd;
  border-color: #0364cd;
  border-radius: 7px;
  font-size: 15px;
}
.el-button--primary:focus, .el-button--primary:hover{
  border-color: #046cdc;
  background: #046cdc;
}
</style>
<style>
.warning {

  border-radius: 7px;
  background: #17264C;
  border: 1px solid #5BA3ED4D;
  margin-top: 0vh !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.warning > div:nth-child(1) {
  padding: 0;
}

.warning > div:nth-child(1) .el-dialog__headerbtn {
  font-size: 22px;
}

.warning > div:nth-child(2) {
  padding: 0;
}

.warning > div:nth-child(3) {
  padding: 0;
}

</style>
