<template>
  <el-row  class="header-row" @click.native="closeExpand">
    <el-col :span="15">
      <div class="grid-content block1 ">
        <div class="company-area">
          <img :src="settingInfo.logo || logoImg" alt="" class="company-area-img">
          <div class="company-area-title">{{ settingInfo.regionName || '将臣科技公司'}}</div>
          <img src="../../../images/comm-shining.png" alt="" class="company-light">
        </div>
        <div class="system-area" :style="{paddingLeft: `${formatLength(settingInfo.cmsName)}px`}">{{ settingInfo.cmsName || '智慧消防管理云平台' }}</div>
      </div>
    </el-col>
    <el-col :span="2"><div class="grid-content block2"></div></el-col>
    <el-col :span="3"><div class="grid-content block2" style="font-size: 18px">{{ format(date) }}</div></el-col>
    <el-col :span="1">
      <div class="grid-content block2">
        <alarm-tone></alarm-tone>
      </div>
    </el-col>
    <el-col :span="3">
      <div class="grid-content block2 avatar">
        <!--   头像区域    -->
       <el-dropdown  >
          <div>
            <el-avatar :size="37" :src="avatar" >
              <img src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"/>
            </el-avatar>
            <span class="username">{{ username }}</span>
            <img src="../../../images/arrow-down2.png" alt="">
          </div>

          <template #dropdown class="dropdown">
            <el-dropdown-menu>
              <el-dropdown-item
                  v-for="item in DialogList"
                  :key="item.id"
                 v-on:click.native="openDialog(item.id)">
                {{ item.name }}
                <i class="el-icon-arrow-right"></i>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </el-col>
    <!--  弹框区域  -->
    <div>
      <setting-dialog ref="settingDialog" :show="show1" @changeShow="changeShow" ></setting-dialog>
      <phone-dialog ref="phoneDialog" :show="show2" @changeShow="changeShow"></phone-dialog>
      <password-dialog ref="updateDialog" :show="show3" @changeShow="changeShow"></password-dialog>
      <export-dialog ref="exportDialog" :show="show4" @changeShow="changeShow"></export-dialog>
      <record-dialog ref="recordDialog" :show="show5" @changeShow="changeShow"></record-dialog>
      <logout-dialog ref="logoutDialog" :show="show6" @changeShow="changeShow"></logout-dialog>
      <deal-dialog :show="show7" @changeShow="changeShow" :state="state" :tableInfo="tableInfo" :recoverInfo="recoverInfo" :alarmInfo="alarmInfo"></deal-dialog>
      <common-dialog :show="show8" @changeShow="changeShow"  ></common-dialog>
      <video-dialog :show="show9" @changeShow="changeShow" :deviceId="deviceId" ref="video" ></video-dialog>
    </div>

  </el-row>
</template>

<script>
import SettingDialog from "@/component/Dialog/SettingDialog";
import PhoneDialog from "@/component/Dialog/PhoneDialog";
import PasswordDialog from "@/component/Dialog/PasswordDialog";
import ExportDialog from "@/component/Dialog/ExportDialog";
import RecordDialog from "@/component/Dialog/RecordDialog";
import CommonDialog from "@/component/Dialog/CommonDialog";
import LogoutDialog from "@/component/Dialog/LogoutDialog";
import {dateFormat} from "@/utils/date"
import DealDialog from "@/component/Dialog/DealDialog";
import VideoDialog from "@/component/Dialog/VideoDialog";
import AlarmTone from "@/component/voice/AlarmTone";
import avatarImg from '@/images/avatar.png'
import bus from "@/utils/eventBus/bus";
import logoImg from '@/images/companyIcon.png'
import {axiosServer} from "@/api/judge";
import {getDeviceVideoByComId} from "@/api/impl/device";
export default {
  name: 'FireHeader',
  props: {
    msg: String
  },
  components:{
    SettingDialog,
    PhoneDialog,
    PasswordDialog,
    ExportDialog,
    RecordDialog,
    LogoutDialog,
    DealDialog,
    AlarmTone,
    CommonDialog,
    VideoDialog
  },
  computed:{
    settingInfo(){
      return this.$store.getters.settingInfo;
    }
  },
  data(){
    return {
      avatar: avatarImg,
      logoImg: logoImg,
      username:localStorage.getItem('username'),
      DialogList:[
        {
          id: 1,
          name: '管理台设置',
          show: 'setting'
        },
        {
          id: 2,
          name: '联系电话',
          show: 'phone'
        },
        {
          id: 3,
          name: '密码修改',
          show: 'update'
        },
        {
          id: 4,
          name: '报表导出',
          show: 'export'
        },
        {
          id: 5,
          name: '历史告警记录',
          show: 'record'
        },
        {
          id: 8,
          name: '常规设置',
          show: 'common'
        },
        {
          id: 6,
          name: '退出登录',
          show: 'logout'
        },

      ],
      interval: null,
      date: new Date(),
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
      show6: false,
      show7: false,
      show8: false,
      show9: false,
      // 区分处理告警数据
      state: 0,
      tableInfo: {},
      recoverInfo: {},
      alarmInfo: {},
      deviceId: 0
    }
  },
  methods:{

    openDialog(id){
      // this.show = !this.show;
      // console.log(this.$refs[show + 'Dialog'])
      // this.$refs[show + 'Dialog'].dialogVisible = true;
      this['show' + id] = true;
      if (id == 5){
        this.$refs.recordDialog.requestData();
      }
    },
    format(date){
      return dateFormat('YYYY-mm-dd HH:MM:SS',date)
    },
    changeShow(e){
      // this.show = false;
      this['show' + e] = false;
    },
    formatLength(cmsName){
      let str = null;
      if (typeof cmsName === 'string' ){
        str = cmsName;
      }else {
        str = '智慧消防管理云平台'
      }

      if (str.length == 7){
        return 90;
      }else if (str.length == 8){
        return 70;
      }else if (str.length == 9){
        return 50;
      }else if (str.length == 10){
        return 35;
      }
    },
    closeExpand(){
      // this.$store.commit('SET_EXPAND', false)
    },
    getVideo(id){
      getDeviceVideoByComId({deviceId: id}).then(res => {
        console.log(res)
        console.log(res.data)
        console.log(res.data.rtspAddress)
        if (res.code === 0){
          if (res.data){
            if (res.data.rtspAddress){
              this.show9 = true;
              this.$refs.video.videoUrl = res.data.rtspAddress;
            }else {
              this.$message.warning("该设备联动摄像头未配置地址")
            }
          }else {
            this.$message.warning("该设备未与摄像头联动")
          }
        }
      })
    }
  },

  created(){
    this.interval = setInterval(() => {
      this.date = new Date();
    },1000)
  },
  mounted(){
    bus.$on('dealDialog', res => {
      this.show7 = res.show;
      this.state = res.state;
      if (res.state == 0){
        this.alarmInfo = res.alarmInfo;
      } else if (res.state == 1){
        this.tableInfo = res.tableInfo;
        this.recoverInfo = res.recoverInfo;
      }else if (res.state == 2) {
        this.tableInfo = res.tableInfo;
      }
    })
    bus.$on('videoDialog', res => {
      this.getVideo(res.id);
      // this.getDeviceVideoByComId(res.id);
    })
  },
  destroyed(){
    bus.$off('dealDialog')
    clearInterval(this.interval)
  }
}
</script>

<style scoped>
  .header-row{
    height: 70px;
    background-color: #101c42;
    background-repeat:no-repeat;
    /*z-index: 1;*/
  }

  .block1{
    background: url(../../../images/top-bg.png) no-repeat;
    font-size: 28px;
    color: rgba(255, 255, 255, 0.8);

    /*padding-left: 360px;*/
    /*padding-top: 22px;*/
    position: relative;

  }

  /*.block1::after{*/
  /*  content: '';*/
  /*  position: absolute;*/
  /*  top: 0;*/
  /*  left: 0;*/
  /*  width: 307px;*/
  /*  height: 80px;*/
  /*  background:  url("../images/位图.png");*/

  /*  z-index:5;*/
  /*}*/
  /*.block1::before{*/
  /*  content: '';*/
  /*  position: absolute;*/
  /*  top: 0;*/
  /*  left: 0;*/
  /*  width: 307px;*/
  /*  height: 80px;*/
  /*  background: linear-gradient(to top, #5699FF, #17264C);*/
  /*  border-radius: 0 0 66px 0;*/
  /*  z-index: 4;*/
  /*}*/
  .block2{
    background: url(../../../images/top-bg2.png) repeat;
    font-size: 14px;
    color: #ffffff;
     margin: 0 auto;
     line-height:70px;
     text-align: center;

  }

  .grid-content {
    min-height: 36px;
    height: 70px;
    display: flex;
  }
  .company-area{
    width: 308px;
    height: 80px;
    background: linear-gradient(to top, #0367D0, #0D1B58);
    z-index: 5;
    border-radius: 0 0 62px 0;
    border-bottom: 1px solid #5BA3ED;
    border-right: 1px solid #5BA3ED;
    box-shadow: 0 6px 9px 0 #00000080;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .company-area-img{
    width: 38px;
    height: 38px;


  }
  .company-area-title{
    margin-left: 10px;
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    background: linear-gradient(180deg,#4f96e7 0%, #d9e7f2 94%);
    -webkit-background-clip: text;
    color: transparent;
    letter-spacing: 2px;
    text-shadow: 0 18px 42px 0 rgba(27,49,109,0.17);
  }
  .company-light{
    position: absolute;
    top: 24px;
    left: 56px;
  }
  .system-area{
    padding-top: 22px;
    padding-left: 60px;
    letter-spacing:7px;
  }
  /* 头像区域 */
  .avatar{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .el-dropdown{
    line-height: 12px;
  }
  .el-dropdown .el-dropdown-selfdefine{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .username{
    margin: 0 13px;
    color: #fff;
  }

  i{
    display: block;
    font-size: 18px;
    line-height: 64px;
    color: #ffffff;

  }
  /* 下拉菜单 */

  .el-dropdown-menu{
    /* 去除边距 */
    padding: 0;
    /* 边框颜色 */
    border: 1px solid rgba(91,163,237,0.30);
    width: 298px;
    min-height: 64px;
    opacity: 1;
    background: rgba(23,38,76,0.96);
    top: 70px!important;
    border-radius: 8px;
  }
  .el-dropdown-menu__item{
    height: 64px;
    line-height: 64px;
    font-size: 18px;
    font-family: PingFangSC, PingFangSC-Semibold;
    font-weight: 600;
    /*text-align: left;*/
    color: #ffffff;
    letter-spacing: 1px;;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .el-dropdown-menu__item:focus, .el-dropdown-menu__item:not(.is-disabled):hover{

    background: transparent;
    color: #fff;
  }
  .el-dropdown-menu__item:first-child:hover{

    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .el-dropdown-menu__item:nth-child(6):hover{
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .el-dropdown-menu{
    background: rgba(23,38,76,0.96)!important;
  }
  .el-dropdown-menu__item:hover{
    background: #101C42!important;
  }

</style>
<style>


/* 去除三角图标 */
.el-popper[x-placement^=bottom] .popper__arrow{
  display: none;
}

/*/deep/.el-dropdown__popper.el-popper[role=tooltip] {*/
/*  top: 85px!important;*/
/*  !*right: 21px!important;*!*/
/*  width: 298px;*/
/*  height: auto;*/
/*  background: rgba(23,38,76,0.96)!important;*/
/*  border: 1px solid rgba(91,163,237,0.30)!important;*/
/*  border-radius: 8px!important;*/
/*  !*left: 0!important;*!*/
/*  !*right: 21px;*!*/
/*}*/

</style>
